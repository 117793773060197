import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import IconButton from '@material-ui/core/IconButton'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import ViewPageIcon from '@material-ui/icons/Pageview'
import PlayArrow from '@material-ui/icons/PlayArrow'
import PublishIcon from '@material-ui/icons/Publish'

import * as AdminProjectsActions from './AdminProjectsActions'
import {
	PROJECT_PARTS_ADMIN_ROUTE,
	PROJECTS_ROUTE,
	USER_HOME_ROUTE
} from '../../../../Services/Constants/RoutesConstants'
import {
	adminProjectInfoRoute,
	adminProjectsRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_PROJECTS_COMPANY,
	ADMIN_PROJECTS_CREATED_AT,
	ADMIN_PROJECTS_DEFAULT_MATERIAL,
	ADMIN_PROJECTS_EMAIL,
	ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID,
	ADMIN_PROJECTS_FILTER_NOT_ADMIN,
	ADMIN_PROJECTS_FILTER_NOT_PUBLISHED,
	ADMIN_PROJECTS_FILTER_NOT_QA,
	ADMIN_PROJECTS_ID,
	ADMIN_PROJECTS_NAME,
	ADMIN_PROJECTS_PARTS_IN_PROJECT,
	ADMIN_PROJECTS_PUBLISHED_AT,
	ADMIN_PROJECTS_STATUS,
	ADMIN_TABLE_NO_DATA_FOUND,
	ADMIN_USERS_ERROR,
	DOWNLOAD_ASSEMBLY,
	EDIT_PROJECT,
	EXPLODE_PROJECT,
	PUBLISH,
	PUBLISH_PROJECT,
	VIEW_PROJECT
} from '../../../../Services/Strings'
import CastorAlert from '../../../Components/alerts/CastorAlert'
import CastorDatePickers from '../../../Components/CastorDatePickers/index.tsx'
import CastorExcelExport from '../../../Components/CastorExcelExport/CastorExcelExport'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorPagination from '../../../Components/CastorPagination/CastorPagination'
import CastorServerFilterBar from '../../../Components/CastorServerFilterBar/CastorServerFilterBar'
import CastorServerSearchBar from '../../../Components/CastorServerSearchBar/CastorServerSearchBar'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Danger } from '../../../Components/thirdParty/CreativeTim/components'
import Table from '../../../Components/thirdParty/CreativeTim/components/Table/Table.jsx'
import Loader from '../../../Loader/Loader'
import Flexbox from 'Scenes/Components/FlexBox'
import {
	getStringItemFromLocalStorage,
	removeItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import offTheShelfImg from '../../../../assets/img/offTheShelfPlaceholder.png'

import '../adminHome.scss'

const PROJECT_SEARCH = 'searchPhraseProject'
const ADMIN_PROJECTS = 'projects'

class AdminProject extends Component {
	constructor(props) {
		super(props)
		this.state = {
			disableFilter: false
		}
		this.props = props
	}

	componentDidMount() {
		const {
			match,
			setupAdminProjectsPage,
			limitProjects,
			searchPhrase,
			filterType
		} = this.props
		const search =
			searchPhrase || getStringItemFromLocalStorage(PROJECT_SEARCH) || ''
		const urlPage = parseInt(match.params['page']) || 1
		const userId = match.params['owner'] || ''
		setupAdminProjectsPage(search, urlPage, userId, limitProjects, filterType)
	}

	componentDidUpdate = prevProps => {
		const { match, limitProjects, searchPhrase, filterType, projectsOwnerId } =
			this.props
		const search =
			searchPhrase || getStringItemFromLocalStorage(PROJECT_SEARCH) || ''
		const urlPage = parseInt(match.params['page']) || 1
		const userId = match.params['owner'] || ''
		if (
			(search && prevProps && search !== prevProps.searchPhrase) ||
			(prevProps && filterType !== prevProps.filterType) ||
			userId !== projectsOwnerId
		) {
			this.fetchOrUpdatePage(urlPage, userId, limitProjects, filterType, search)
		}
	}

	componentWillUnmount() {
		if (!this.props.history?.location?.pathname?.includes(ADMIN_PROJECTS)) {
			removeItemFromLocalStorage(PROJECT_SEARCH)
			this.props.onAdminProjectsUnmounted()
		}
	}

	renderAlert() {
		const {
			showingSimpleAlertText,
			showingSimpleAlertTitle,
			projectWaitingForPublishing,
			publishConfirmed,
			cancelAlert
		} = this.props
		return (
			<CastorAlert
				show={showingSimpleAlertTitle}
				headerTitle={showingSimpleAlertTitle}
				onConfirm={() => publishConfirmed(projectWaitingForPublishing)}
				onCancel={() => cancelAlert()}
				confirmOptionalText={PUBLISH}
			>
				{showingSimpleAlertText}
			</CastorAlert>
		)
	}

	renderDatepickerAlert() {
		const { showDatepicker, datePickersToggled, downloadReport } = this.props
		return (
			<CastorDatePickers
				show={showDatepicker}
				onCancel={() => datePickersToggled(false)}
				downloadReport={(startDate, endDate) =>
					downloadReport(startDate, endDate)
				}
			/>
		)
	}

	publishClicked(project) {
		this.props.publishProjectClicked(project)
	}

	fetchOrUpdatePage(
		pageNumber,
		projectsOwnerId,
		limitProjects,
		filterType,
		searchPhrase
	) {
		if (pageNumber === 1) {
			this.props.setupAdminProjectsPage(
				searchPhrase || '',
				pageNumber,
				projectsOwnerId,
				limitProjects,
				filterType
			)
		} else {
			this.setState({ disableFilter: true })
			this.props.history.push(adminProjectsRoute(projectsOwnerId, 1))
		}
	}

	explodeClicked(project) {
		const { explodeProjectClicked } = this.props
		explodeProjectClicked(project)
	}

	wrapButtonWithLinkIfEnabled(button, link) {
		return button.props.disabled ? button : <Link to={link}>{button}</Link>
	}

	renderTableLineButtons(project) {
		const {
			onProjectClick,
			onProjectDownloadClick,
			projectDownloadUrlLoading
		} = this.props
		const viewButDisabled = !['published', 'complete', 'approved'].includes(
			project.status
		)
		const viewButton = Feature.isFeatureOn(
			FeatureComponentId.ADMIN_SHOW_PROJECT
		) ? (
			this.wrapButtonWithLinkIfEnabled(
				<IconButton
					title={VIEW_PROJECT}
					disabled={viewButDisabled}
					data-qa="data-qa-admin-view-project"
				>
					<ViewPageIcon />
				</IconButton>,
				USER_HOME_ROUTE + PROJECTS_ROUTE + '/' + project.id
			)
		) : (
			<div></div>
		)
		const editButton = this.wrapButtonWithLinkIfEnabled(
			<IconButton title={EDIT_PROJECT} onClick={() => onProjectClick(project)}>
				<EditIcon />
			</IconButton>,
			PROJECT_PARTS_ADMIN_ROUTE + '/' + project.id
		)
		const downloadButton = () => {
			//TODO: hot fix CAS-4775
			const disableDownload = true
			if (!project.isAssembly || disableDownload) {
				return <div />
			}
			if (projectDownloadUrlLoading[project.id]) {
				return (
					<Loader
						load={projectDownloadUrlLoading}
						size={20}
						left={10}
						top={0}
						showFlex={false}
						wrapperClassName="admin--projects--icon-loader"
					/>
				)
			}
			return (
				<IconButton
					title={DOWNLOAD_ASSEMBLY}
					onClick={() => onProjectDownloadClick(project.id)}
				>
					<CloudDownloadIcon />
				</IconButton>
			)
		}
		const infoButton = this.wrapButtonWithLinkIfEnabled(
			<IconButton title={'info'} onClick={() => onProjectClick(project)}>
				<InfoIcon />
			</IconButton>,
			adminProjectInfoRoute(project.id)
		)

		const publishButton = Feature.isFeatureOn(
			FeatureComponentId.ADMIN_PUBLISH_PROJECT
		) ? (
			<IconButton
				title={PUBLISH_PROJECT}
				disabled={project.status !== 'complete'}
				onClick={this.publishClicked.bind(this, project)}
				style={styles.iconButton}
			>
				<PublishIcon />
			</IconButton>
		) : (
			<></>
		)

		const explodeButton = (
			<IconButton
				title={EXPLODE_PROJECT}
				disabled={project.status !== 'pausedBeforeExplode'}
				onClick={this.explodeClicked.bind(this, project)}
				style={styles.iconButton}
			>
				<PlayArrow />
			</IconButton>
		)
		return [
			publishButton,
			explodeButton,
			editButton,
			viewButton,
			infoButton,
			downloadButton()
		]
	}

	tableLine(project) {
		const customizeOrganizationsIsOn = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_ORGANIZATIONS
		)

		return [
			<div>
				<img
					src={project.assemblyImage ? project.assemblyImage : offTheShelfImg}
					style={styles.thumbnail}
				/>
			</div>,
			<div>
				<h4> {project.name} </h4>
				<p>
					{ADMIN_PROJECTS_ID}: {project.id}
				</p>
				<p>
					{ADMIN_PROJECTS_EXTERNAL_ASSEMBLY_FILE_ID}:{' '}
					{project.externalAssemblyFileId}
				</p>
				<p>
					{ADMIN_PROJECTS_PARTS_IN_PROJECT}: {project.partCount}.{' '}
					{ADMIN_PROJECTS_DEFAULT_MATERIAL}:{' '}
					{project.defaultMaterial?.name || project.defaultMaterial}
				</p>
				<p>
					{ADMIN_PROJECTS_STATUS}: {project.status}
				</p>
				<p>
					{ADMIN_PROJECTS_CREATED_AT}:{' '}
					{new Date(project.createdAt).toLocaleString()}
				</p>
				{project.publishedAt ? (
					<p>
						{ADMIN_PROJECTS_PUBLISHED_AT}:{' '}
						{new Date(project.publishedAt).toLocaleString()}
					</p>
				) : (
					''
				)}
			</div>,
			<div>
				<p>
					{ADMIN_PROJECTS_NAME}: {project.owner?.name}
				</p>
				<p>
					{ADMIN_PROJECTS_EMAIL}: {project.owner?.email}
				</p>
				{customizeOrganizationsIsOn ? (
					<></>
				) : (
					<p>
						{ADMIN_PROJECTS_COMPANY}: {project.owner?.company}
					</p>
				)}
			</div>,
			<Flexbox alignItems="center" className="admin-buttons-wrapper">
				{this.renderTableLineButtons(project)}
			</Flexbox>
		]
	}

	renderSearchBar = () => (
		<div className="admin-data-table-search-field">
			<CastorServerSearchBar
				triggerUpdateField={this.props.filterType}
				placeholder="Search by name/ ID"
				searchPhrase={this.props.searchPhrase}
				setSearchPhrase={this.setSearchPhrase}
			/>
		</div>
	)

	renderFilterBar = () => {
		const renderItems = [
			{ value: 'not_published', key: ADMIN_PROJECTS_FILTER_NOT_PUBLISHED },
			{ value: 'not_qa', key: ADMIN_PROJECTS_FILTER_NOT_QA },
			{ value: 'not_admin', key: ADMIN_PROJECTS_FILTER_NOT_ADMIN }
		]

		return (
			<CastorServerFilterBar
				disableFilter={this.state.disableFilter}
				value={this.props.filterType}
				onChange={this.setFilterType}
				items={renderItems}
			/>
		)
	}

	exportData = () => {
		return (
			<div className="admin-data-table-export-data">
				<CastorExcelExport
					disabled={!this.props.showExportExcel}
					isLoading={this.props.isAdminProjectReportLoading}
					onClick={() => this.props.datePickersToggled(true)}
				/>
			</div>
		)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
		setStringItemToLocalStorage(PROJECT_SEARCH, searchPhrase)
		if (!searchPhrase) {
			this.fetchOrUpdatePage(
				this.props.pageNumber,
				this.props.projectsOwnerId,
				this.props.limitProjects,
				this.props.filterType,
				searchPhrase
			)
		}
	}

	setFilterType = filterType => {
		this.props.setFilterType(filterType)
	}

	renderTable() {
		const {
			showPagination,
			pageNumber,
			isLastPage,
			projectsOwnerId,
			totalProjects,
			limitProjects,
			showNoProjectsAlert,
			showAdminUsersAlert,
			loading
		} = this.props

		const renderTable = () => {
			if (showNoProjectsAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_TABLE_NO_DATA_FOUND}</Danger>
					</div>
				)
			}
			if (showAdminUsersAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_USERS_ERROR}</Danger>
					</div>
				)
			}
			return (
				<>
					<Table
						tableHead={tableHeaders}
						tableData={tableContent}
						loading={loading}
						addMinHeight
					/>
					<CastorPagination
						showPagination={showPagination}
						pageNumber={pageNumber}
						isLastPage={isLastPage}
						linkTo={pageNumber =>
							adminProjectsRoute(projectsOwnerId, pageNumber)
						}
						total={totalProjects}
						limit={limitProjects}
					/>
				</>
			)
		}

		const tableHeaders = [
			'',
			'Project Information',
			'User information',
			'Actions'
		]
		const tableContent = this.props.projects.map(project =>
			this.tableLine(project)
		)
		return (
			<div className="admin-home-page">
				<div className="admin-data-table-add-item-button">
					{this.renderFilterBar()}
					{this.renderSearchBar()}
					{this.exportData()}
				</div>
				{renderTable()}
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={'Projects'}>
				<div style={{ minHeight: '50vh' }}>
					{this.renderAlert()}
					{this.renderDatepickerAlert()}
					<CastorForm
						formTitle={`${
							this.props.userProjectsName
								? `${this.props.userProjectsName}'s`
								: ''
						} Projects`}
						formSubTitle="The latest projects"
						content={this.renderTable()}
						style={{ maxWidth: 'unset' }}
					/>
				</div>
			</NavBarAndMaterial>
		)
	}
}

const styles = {
	textField: { width: '70%', minWidth: 350 },
	smallTextField: { width: 150, marginLeft: 6, marginRight: 6 },
	chart: { height: '300px', marginRight: 20, marginTop: 20, flexGrow: 5 },
	horizontalFlex: { flex: 1, alignItems: 'center' },
	xAxisLabel: { textAlign: 'center' },
	divider: { margin: 10 },
	thumbnail: { width: 150, maxHeight: 150 },
	card: { marginLeft: 20, marginRight: 20 },
	iconButton: { height: 40, width: 75, alignSelf: 'center' },
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	}
}

const mapStateToProps = ({ AdminProjectsReducer }) => {
	return {
		...AdminProjectsReducer
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ ...AdminProjectsActions }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(AdminProject)
