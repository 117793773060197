import React, { FC, memo, useMemo } from 'react'

import cx from 'classnames'
import { VictoryPie } from 'victory'

import Flexbox from 'Scenes/Components/FlexBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import {
	benefitsAnalysisType,
	IBenefitStateType
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisInterfaces'
import ProjectResultDistributionCustomLabel from 'Scenes/Home/ProjectAnalysis/ProjectResultDistributionChart/ProjectResultDistributionCustomLabel'
import {
	benefitAnalysisColors,
	piePassedColor,
	piePassedGradientStartColor
} from 'Services/colors'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	styles: {
		card: Record<string, unknown>
		horizontalFlex: Record<string, unknown>
		footerFlex: Record<string, unknown>
		footer: Record<string, unknown>
		pieSuper: Record<string, unknown>
		text: Record<string, unknown>
	}
	numberOfPrintableParts: any
	numberOfAllBenefits: any
	benefitsState: any
	isAssembly: boolean
}

const VictoryPieTSX: any = VictoryPie

const BenefitCostAnalysisChart: FC<IProps> = ({
	styles,
	numberOfPrintableParts,
	numberOfAllBenefits,
	benefitsState,
	isAssembly
}) => {
	const currentBenefitState: IBenefitStateType = useMemo(
		() =>
			benefitsState.find(
				(benefit: IBenefitStateType) =>
					benefit.name === benefitsAnalysisType.costSaving
			),
		[benefitsState]
	)

	const renderPrintableBenefitsResultsStatsResultLabel = () => {
		return (
			<div className={cx('project-results--label--title')}>
				<div className="project-results--label--title--info">
					<IconFactory
						iconName={'benefit'}
						className="project-results--label--title--icon benefit-sign"
					/>
					<div className="project-results--label--text">
						{getString('PART_ANALYSIS_TABS_TITLE_COST_COMPARTION')}
					</div>
				</div>
				<div
					className={cx('project-results--label--info', {
						hidden: !numberOfAllBenefits,
						high: isAssembly
					})}
				>
					<span className="project-results--label--title--out-of">
						{getString('PROJECT_ANALYSIS_BENEFITS_COST_ANALYSIS').format(
							numberOfPrintableParts,
							currentBenefitState?.numberOfParts
						)}
					</span>
				</div>
			</div>
		)
	}

	const renderPrintableBenefitsResultsStatsPie = () => {
		const chartData = currentBenefitState?.printableChartData
		const noCurrentBenefit = currentBenefitState?.numberOfParts === 0
		const noBenefits = numberOfAllBenefits === 0

		return (
			<div
				className={cx('project-results--cost-saving-chart', {
					'limited-height': isAssembly,
					'grayed-out': noBenefits || noCurrentBenefit
				})}
			>
				<svg className="project-results--cost-saving-chart--gradient">
					<defs>
						<linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
							<stop offset="0%" stopColor={piePassedGradientStartColor} />
							<stop offset="100%" stopColor={piePassedColor} />
						</linearGradient>
					</defs>
				</svg>
				<VictoryPieTSX
					labelComponent={
						noBenefits ? <div /> : <ProjectResultDistributionCustomLabel />
					}
					data={chartData}
					style={{
						labels: { fill: 'dark gray', fontSize: 19 },
						parent: {
							width: 400,
							height: 400
						},
						data: {
							stroke: (datum: Record<string, unknown>) =>
								datum.y && datum.stroke,
							strokeWidth: 3
						}
					}}
					innerRadius={80}
					radius={125}
					colorScale={[
						benefitAnalysisColors[benefitsAnalysisType.costSaving],
						'url(#gradient1)'
					]}
				/>
			</div>
		)
	}

	return (
		<Flexbox
			flexDirection="column"
			className={cx('project-results--block', {
				'without-assembly-block': !isAssembly
			})}
			style={styles.footerFlex}
		>
			{renderPrintableBenefitsResultsStatsResultLabel()}
			{renderPrintableBenefitsResultsStatsPie()}
		</Flexbox>
	)
}

export default memo(BenefitCostAnalysisChart)
