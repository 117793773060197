import { Dispatch } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction } from 'redux'

import { invert, isBoolean, isEmpty } from 'lodash'

import { store } from '../../../../..'
import {
	AM_MATERIAL_CATEGORY_CHANGED,
	AM_MATERIAL_CHANGED,
	AM_MATERIAL_SELECTOR_TOGGLED,
	AM_MATERIAL_TYPE_CHANGED,
	AM_SUB_CATEGORY_CHANGED,
	CHAIN_BENEFITS_FORM_EDIT_CANCELLED,
	CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
	CONFIGURATION_CALCULATED,
	DO_REFRESH_CONFIGURATIONS,
	FEA_ANALYSIS_ALERT_CANCELED,
	FEA_ANALYSIS_ALERT_LOADER_TOGGLED,
	FEA_ANALYSIS_DATA_FETCHED,
	FEA_ANALYSIS_DATA_RESET,
	FEA_NEW_ANALYSIS_CLICKED,
	FEA_NEW_ANALYSIS_TOGGLED,
	FEA_OLD_ANALYSIS_TOGGLED,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	LIFE_CYCLE_CHANGED,
	LIFE_CYCLE_FORM_SUBMITED,
	MATERIAL_CATEGORY_CHANGED,
	MATERIAL_CHANGED,
	MATERIAL_SELECTOR_TOGGLED,
	MATERIAL_TYPE_CHANGED,
	NOTIFICATION_CLEARED,
	OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
	PART_FEA_ANALYSIS_DATA_FETCHED,
	SIMPLE_CONFIGURATION_INHOUSE_TOGGLED,
	SIMPLE_CONFIGURATION_SELECTOR_COMPANY_CHANGED,
	SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED,
	SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED,
	SOLUTION_CONFIGURATION_WAS_CHANGED,
	USER_CUSTOM_SETTINGS_UPDATED
} from '../../../../../global actions/types'
import {
	ALERT_CALCULATION_FINISHED,
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../../global actions/types/CastorAlertTypes'
import {
	ALTERNATIVE_SOLUTION_ADDED,
	ASSEMBLING_PARAMS_UPDATED,
	BATCH_SIZE_CHANGED,
	CHAIN_BENEFIT_VALUE_CHANGED,
	CHAIN_BENEFITS_CHECK_BOX_CHANGED,
	CHAIN_BENEFITS_UPDATE_FINISHED,
	CHAIN_BENEFITS_UPDATE_STARTED,
	CHAIN_BENEFITS_YEARS_OF_DEMAND_CHANGED,
	CHANGE_ORGANIZATION_TO_UPDATE_CONFIGURATION,
	CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED,
	CONFIGURATION_ANALYSIS_3D_MODAL_OPENED,
	CONFIGURATION_INHOUSE_ALERT_OPENED,
	CONFIGURATION_IS_SET_LEADING,
	CONFIGURATION_TOLERANCES_LOADING_TOGGLED,
	CONFIGURATION_WALL_THICKNESS_CANCELLED,
	CONFIGURATION_WALL_THICKNESS_CLICKED,
	CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED,
	CONFIGURATION_WALL_THICKNESS_LOADING_UPDATED,
	COST_COMPARISON_EDIT_CLICKED,
	COST_COMPARISON_EDIT_FINISHED,
	COST_COMPARISON_EDIT_STARTED,
	COST_COMPARISON_FORM_CANCELLED,
	COST_COMPARISON_QUANTITY_CHANGED,
	COST_COMPARISON_RESET_CHAIN_BENEFITS,
	END_POLLING_TRAY_ORIENTATION_DATA,
	EXPORT_3D_LINK_UPDATED,
	EXTEND_CONFIGURATION_ORGANIZATION_SETTINGS,
	FINISHED_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS,
	GEOMETRY_ANALYSIS_ALERT_CANCELLED,
	GEOMETRY_ANALYSIS_ALERT_OPENED,
	GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED,
	GET_ALTERNATIVE_SOLUTION_CALCULATED,
	GLOBAL_CHAIN_BENEFITS_CHECK_BOX_CHANGED,
	LEAD_TIME_EDIT_FINISHED,
	LEAD_TIME_EDIT_STARTED,
	LEAD_TIME_FORM_EDIT_CLICKED,
	LEAD_TIME_FORM_FORM_CANCELLED,
	LEAD_TIME_GRAPH_UNLOCKED_ERROR,
	LEAD_TIME_GRAPH_UNLOCKED_STARTED,
	LEAD_TIME_GRAPH_UNLOCKED_SUCCESS,
	MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED,
	MAIN_PART_PART_PRINT_ISSUES_UPDATED,
	MAIN_PART_UPDATE_CHAIN_BENEFITS,
	MATERIAL_COMPARISON_SHOW_ALL_CLICKED,
	NEW_SOLUTION_CONFIGURATION_ADDED,
	ORIENTATION_CONFIRM_NO_CHANGE,
	OUT_SOURCED_CNC_MANUFACTURING_TOGGLE_CHANGE,
	PART_ANALYSIS_LEAD_FORM_SUBMITED,
	PART_ANALYSIS_METAL_FORM_SUBMITED,
	PART_ANALYSIS_PLASTIC_FORM_SUBMITED,
	PART_CONFIGURATIONS_SOLUTIONS_UPDATED,
	PART_IMAGE_TABS_CLICKED,
	PROJECT_PDF_OPTIONS_SUCCESS,
	SOLUTION_ANALYSIS_PART_DISABLED,
	SOLUTION_CONFIGURATION_MESH_HEALING_PART_UPDATED,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
	SOLUTION_CONFIGURE_BACK_CLICKED,
	SOLUTION_CONFIGURE_CALCULATION_CLICKED,
	SOLUTION_CONFIGURE_CALCULATION_FAILED,
	SOLUTION_CONFIGURE_CANCELLED,
	SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_CLICKED,
	SOLUTION_CONFIGURE_FILTER_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_FILTER_MODAL_CANCELED,
	SOLUTION_CONFIGURE_FILTER_MODAL_CONFIRMED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CANCELED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CONFIRM,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CANCELED,
	SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CONFIRM,
	SOLUTION_CONFIGURE_POST_PROCESSES_CANCELED,
	SOLUTION_CONFIGURE_POST_PROCESSES_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_POST_PROCESSES_MODAL_CONFIRMED,
	SOLUTION_CONFIGURE_POST_PROCESSES_TOGGLED,
	SOLUTION_CONFIGURE_PRIORITIZE_CHANGED,
	SOLUTION_CONFIGURE_PRIORITIZE_FEATURE_CLICKED,
	SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CANCELED,
	SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CONFIRMED,
	SOLUTION_CONFIGURE_PRIORITY_REMOVED,
	SOLUTION_CONFIGURE_PRIORITY_TOGGLED,
	SOLUTION_CONFIGURE_RESET_CLICKED,
	SOLUTION_CONFIGURE_SHOW_ORIENTATION_PRINTING_MODEL,
	SOLUTION_CONFIGURE_STATE_UPDATED,
	SOLUTION_DO_SETUP,
	SOLUTION_FETCHED,
	SOLUTION_FINISH_REMOVED,
	SOLUTION_NAME_CHANGED,
	SOLUTION_NAME_FOCUSED_IN,
	SOLUTION_NAME_FOCUSED_OUT,
	SOLUTION_NAME_UPDATED,
	SOLUTION_ORIENTATION_CLICKED,
	SOLUTION_PRESSED,
	SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED,
	SOLUTION_REMOVED,
	SOLUTION_RETRIEVE_RESULTS_CLICKED,
	SOLUTION_STARRED,
	SOLUTION_START_REMOVED,
	SOLUTION_TOUR_STEP_UPDATED,
	SOLUTION_UNMOUNTED,
	START_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS,
	START_POLLING_TRAY_ORIENTATION_DATA,
	TOGGLE_ASSEMBLING_COST_MODAL,
	TOLERANCES_CANCELLED,
	TOLERANCES_CLICKED,
	TOOLING_TOGGLE_CHANGE_ERROR,
	TOOLING_TOGGLE_CHANGE_SUCCESS,
	TOOLING_TOGGLE_RESET,
	TRAY_ORIENTATION_INFO_BUTTON_CLICKED,
	UPDATE_CONFIGURATION_NAME_TOGGLED,
	UPDATE_QUANTITY_TOGGLED
} from '../../../../../global actions/types/partAnalysisTypes'
import { TOUR_PAUSE_TOGGLED } from '../../../../../global actions/types/takeATourTypes'
import {
	analysisTabLSKey,
	json,
	manufacturingMethodTypes,
	materialTypes,
	partResults
} from '../../../../../Services/Constants'
import {
	CUSTOMIZE_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE,
	SEND_TO_SERVICE_BUREAU_ROUTE,
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from '../../../../../Services/Constants/RoutesConstants'
import history from '../../../../../Services/history'
import { IConfigurationColors } from '../../../../../Services/models/ConfigurationColors'
import {
	CostData,
	IOptionalMetalFormParameters,
	LeadData
} from '../../../../../Services/models/CostComparisonModels'
import {
	Feature,
	FeatureComponentId
} from '../../../../../Services/models/Features'
import {
	ChainBenefitsNames,
	IChainBenefits
} from '../../../../../Services/models/IChainBenefits'
import { IClusterPart } from '../../../../../Services/models/ICluster'
import { IFilter } from '../../../../../Services/models/IFilter'
import { ImanufacturingTypes } from '../../../../../Services/models/IManufacturingTypes'
import { Part, WeightReductionType } from '../../../../../Services/models/IPart'
import { IPriority } from '../../../../../Services/models/IPriority'
import { ISolutionConfiguration } from '../../../../../Services/models/ISolutionConfiguration'
import {
	FeaAnalysisResults,
	FeaResult,
	FeaStatus,
	ISolutionFea
} from '../../../../../Services/models/ISolutionFea'
import { PartPrintIssue } from '../../../../../Services/models/PartPrintIssue'
import {
	getPrintingOrientation,
	getProjectPDFOptions,
	sendReNewSubscriptionRequest,
	switchAlternativeSolution,
	updateClusterAssemblingParams,
	updateLeadTime,
	updateOutSourcedCNCManufacturing,
	updatePartAnalysis,
	updatePartTolerance
} from '../../../../../Services/Network'
import {
	changePartPrintIssueActivity,
	combineAroundPart,
	createFeaRequest,
	createMeshHealing,
	createOrUpdateSolution,
	exportConfigurationData,
	getCaeplexRedirectURL,
	getConfigurationOrganizationSettings,
	getFeaAnalysisResults,
	meshHealingSendToPrint,
	partDownload,
	removeSolution,
	saveFeaAnalysisData,
	updateCNCProps,
	updateConfigurationTraditionalMethod,
	updateLeadingConfiguration,
	updateLockLeadTimeGraph,
	updatePartWeightReductionType,
	updateSolutionName,
	updateSolutionQuantity,
	updateSolutionStarred,
	updateToolingParam,
	updateUserCustomCNCProps,
	userSendToPrint
} from '../../../../../Services/Network/PartAnalysisNetwork'
import {
	COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
	CONFIGURATION_CALCULATION_ERROR,
	CONFIGURATION_MESH_HEALING_CONFIRM,
	CONFIGURATION_MESH_HEALING_EXPLENATION,
	CONFIGURATION_MESH_HEALING_OPERATED_EXPLENATION,
	CONFIGURATION_MESH_HEALING_OPERATED_TITLE,
	DONE,
	DOWNLOAD,
	FAILED_PROCESSING_PART_ALERT_TITLE,
	MESH_HEALING,
	OK,
	ORIENTATION_CHANGED_MSG,
	ORIENTATION_FAIL_ON_SIZE_MSG,
	PRINTING_ORIENTATION_NEW_CONF_MSG,
	SEND_TO_SERVICE_BUREAU_TITLE,
	SHOW_NOTIFICATION,
	TOLERANCES_UPDATE_SUCCESS_MSG,
	TRAY_ORIENTATION_RESULT_WARNING,
	WALL_THICKNESS_NOTIFICATION_FAILED,
	WALL_THICKNESS_NOTIFICATION_SUCCEDED,
	WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_HEADER,
	WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_TEXT
} from '../../../../../Services/Strings'
import { getString } from '../../../../../Services/Strings/StringService'
import { getTheme } from '../../../../../themes/getTheme'
import { AlertType } from '../../../../Components/alerts/AlertTypes'
import { AmMaterialSelectorService } from '../../../../Components/MaterialSelector/AmMaterialSelector/AmMaterialSelectorService'
import { MaterialSelectorService } from '../../../../Components/MaterialSelector/TmMaterialSelector/MaterialSelectorService'
import {
	ISimpleConfigurationCompany,
	SimpleConfigurationSelectorService
} from '../../../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { handleWeightReductionState } from '../../../../Components/WeightReduction/WeightReductionService'
import {
	onCamExistenceChange,
	updateConfiguration
} from '../MainPartAnalysisActions'
import { ConfigurationResultTypes } from './ConfigurationResultTypes'
import { CalculationType } from './SolutionAnalysis'
import { assemblingCalculationType } from './SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable/CostAnalysisTableConstants'
import { CostComparisonService } from './SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostComparisonService'
import { LeadTimeService } from './SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import {
	findEffectivePoints,
	getManufacturingMethodName,
	getPostProcessIdsWithAbnormalValues,
	getUsedOrganization,
	SolutionAnalysisService
} from './SolutionAnalysisService'
import { Action, ActionWithPayload } from 'global actions/ActionModels'
import queryString from 'query-string'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { POST_PROCESSES_BLOCK_ID_HASH } from 'Scenes/Home/Customize/CustomizeUserPostProcesses/Constants'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import {
	removeItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { IConfiguration } from 'Services/models/IConfiguration'
import { IPostProcess } from 'Services/models/IPostProcess'
import { IPrinter } from 'Services/models/IPrintersTypes'
import { ISolution } from 'Services/models/ISolution'
import { IUserProvider } from 'Services/models/IUserProvider'
import { printLeadTimeBreakDown } from 'Services/Utils/consoleQAUtils'

const { tabOrder } = getTheme()
let solutionAnalysisService: SolutionAnalysisService
let materialsService: MaterialSelectorService
let amMaterialsService: AmMaterialSelectorService
let simpleConfigurationSelectorService: SimpleConfigurationSelectorService
const costComparisonService = new CostComparisonService()
const leadTimeService = new LeadTimeService()

export const setupSolution = (
		configuration: any,
		part: Part,
		cluster: any,
		filters: IFilter[],
		priorities: IPriority[],
		initialBatchSize: number,
		partMaterial: any,
		materials: Array<any>,
		solution: any,
		allPostProcessData: any,
		optionalPostProcessAvailability: any,
		optionalPostProcessesBreakDown: any,
		toleranceIncluded: boolean,
		userCurrencySign: string,
		printersFullData: Array<ISimpleConfigurationCompany>,
		isWeightReductionPart: boolean,
		SolutionFea: ISolutionFea,
		partPrintIssues: PartPrintIssue[],
		materialCategories: any[],
		disablePart: boolean,
		printerMaterials: any,
		printerMaterialsSubCategories: any,
		printerMaterialsCategories: any,
		feaId?: string
	): any => {
		return async (dispatch: any) => {
			// for new configurations
			if (!configuration.organizationId) {
				configuration.organizationId =
					part?.organizationId || cluster?.organizationId
			}
			const {
				user: { defaultSettings },
				MainPartAnalysisReducer: {
					allConfigurationsOrganizationSettings,
					drawingCostPercentage: drawingPartPercentage
				}
			} = store.getState()
			const customizationSettings =
				allConfigurationsOrganizationSettings?.[configuration.organizationId]
					?.customizationSettings || defaultSettings
			const drawingCostPercentage =
				customizationSettings.drawingCostPercentage || drawingPartPercentage

			solutionAnalysisService = new SolutionAnalysisService(
				configuration,
				optionalPostProcessAvailability,
				allPostProcessData,
				optionalPostProcessesBreakDown
			)
			const isAmOriginalMaterial =
				!!configuration?.filters?.printerMaterialSubCategory
			const initialMaterialType =
				configuration?.material?.type || partMaterial?.type
			const initialMaterialCategory =
				configuration?.material?.category || partMaterial?.category
			const initMaterial = configuration?.material?.name
				? configuration?.material
				: partMaterial
			materialsService = new MaterialSelectorService(
				materials,
				initialMaterialType,
				initialMaterialCategory,
				initMaterial,
				materialCategories
			)

			const initialAmMaterialType =
				solution?.printerMaterial?.type || configuration?.amMaterial?.type
			const initialAmMaterialCategory =
				solution?.printerMaterial?.category ||
				configuration?.amMaterial?.category
			const initSubCategory =
				solution?.printerMaterial?.subCategory ||
				configuration?.amMaterial?.subCategory
			const initAmMaterialId = configuration?.amMaterial?.materialId || ''
			amMaterialsService = new AmMaterialSelectorService(
				printerMaterials,
				initialAmMaterialType,
				initialAmMaterialCategory,
				initSubCategory,
				printerMaterialsSubCategories
			)
			simpleConfigurationSelectorService =
				new SimpleConfigurationSelectorService(
					printersFullData,
					configuration.filters?.inHousePrinters,
					true
				)
			const solutionPriorities: Map<string, number> =
				solutionAnalysisService.setupSolutionPriorities(
					configuration.priorities,
					priorities
				)

			const configurationMethod: ImanufacturingTypes =
				configuration?.manufactureMethod ||
				(initialMaterialType === materialTypes?.plastic
					? ImanufacturingTypes.mold
					: ImanufacturingTypes.cnc)

			const manufacturingMethod: string =
				manufacturingMethodTypes[configurationMethod]

			const { materialTypesList, categoriesList, materialsList } =
				materialsService.getDataList()

			const { amMaterialTypesList, amCategoriesList, amSubCategories } =
				amMaterialsService.getDataList()

			const colors: IConfigurationColors =
				solutionAnalysisService.getSolutionColor(disablePart)
			const resultTitle: string =
				solutionAnalysisService.getSolutionResultTitle()
			const configurationResult: string =
				solutionAnalysisService.getSolutionResult(disablePart)
			const resultBody: string =
				solutionAnalysisService.getSolutionResultBody(disablePart)
			const solutionName: string =
				solutionAnalysisService.getSolutionName(configuration)
			const disableConfiguration: boolean = false
			const customConfiguration: boolean =
				solutionAnalysisService.isCustomConfiguration(configuration)
			const costBenefit: any = solutionAnalysisService.getCostBenefit(solution)
			const timeBenefit: any = solutionAnalysisService.getTimeBenefit(solution)
			const alternativeSolutions = configuration.alternativeSolutions || []
			const alternativeSolutionsPrintersList =
				solutionAnalysisService.generateAlternativeSolutionsPrintersList(
					alternativeSolutions,
					solution
				)
			const costDetails: any =
				solutionAnalysisService.createCostDetailsPostProcessesLabels(
					configuration?.postProcessesOptional,
					solution?.costDetails
				)

			const postProcessToggles: any =
				solutionAnalysisService.setupSolutionPostPostProcessToggles(
					configuration.id,
					toleranceIncluded,
					configuration?.postProcessesOptional,
					solution
				)
			const PostProcessOnCounter: number =
				solutionAnalysisService.howManyPostProcessesOn(configuration)
			const prioritiesToggles: any =
				solutionAnalysisService.setupSolutionPrioritiesToggles(
					configuration.priorities,
					priorities
				)

			const initialSolutionBatchSize =
				configuration.quantity || initialBatchSize

			const usedOrganization = getUsedOrganization(
				configuration,
				part || cluster
			)

			let costData: CostData = new CostData()
			let leadTimeData: LeadData = new LeadData()

			if (
				(part || cluster) &&
				configuration.solution &&
				configuration.costResults
			) {
				const configurationPart = configuration.part || configuration.cluster
				costData = costComparisonService.getCostData(
					configuration.costResults,
					manufacturingMethod,
					userCurrencySign,
					solution?.costDetails?.totalCost || 0,
					isAmOriginalMaterial,
					drawingCostPercentage,
					configurationPart?.isDrawing ||
						configurationPart?.formatType === METADATA,
					configurationPart?.blockManufacturingMethodOperation,
					configuration?.isSpecifiedQuantity || false
				)
			}

			if (configuration.leadTimeResults) {
				leadTimeData = leadTimeService.getLeadData(
					configuration.leadTimeResults,
					manufacturingMethod,
					configuration.unlockLeadTimeGraph,
					configuration.quantity,
					(!!cluster?.standardCost || !!part?.standardCost) &&
						!configuration.standardCost,
					configuration,
					isAmOriginalMaterial
				)
			}

			const trayOrientationVector =
				solutionAnalysisService.getSolutionOrientationVector(
					configuration,
					solution
				)
			const configurationPrintIssues = partPrintIssues.filter(
				partPrintIssue =>
					partPrintIssue.configurationId === configuration.id ||
					!partPrintIssue.configurationId
			)
			const analysisResultsRows =
				solutionAnalysisService.getAnalysisResultsRows(
					solution,
					configuration,
					configurationPrintIssues,
					trayOrientationVector,
					part?.isDrawing,
					part?.formatType === METADATA
				)

			const failedPrintIssuesIds =
				solutionAnalysisService.getFailedPrintIssues(analysisResultsRows)
			let solutionOrientationVector: number[] =
				trayOrientationVector && trayOrientationVector.length
					? trayOrientationVector
					: []

			const trayOrientationCustom = solutionAnalysisService.isOrientationCustom(
				configuration,
				solution
			)
			const chosenOrientation = solutionAnalysisService.getChosenOrientation(
				part?.trayOrientations?.data || part?.trayOrientations || [],
				trayOrientationVector
			)

			let feaAnalysisResults: FeaAnalysisResults | undefined = undefined
			let showFeaAnalysisAlert = false
			let showMechanicalTab = false

			const searchParams = queryString.parse(window.location.search)
			const problemHash = searchParams?.problemHash
			const preparingWeightReduction =
				SolutionFea?.part?.weightReductionType ===
				WeightReductionType.PREPARING_FOR_WEIGHT_REDUCTION

			if (
				feaId &&
				SolutionFea?.id === feaId &&
				!problemHash &&
				preparingWeightReduction
			) {
				//the user cancelled weight reduction operation
				//change the part weight status to  suitable for weight reduction, in
				// the server and in the client
				await updatePartWeightReductionType(
					part.id,
					WeightReductionType.SUITABLE_FOR_WR
				)
				part.weightReductionType = WeightReductionType.SUITABLE_FOR_WR
			}

			if (feaId && SolutionFea?.id === feaId && problemHash) {
				// the user came back from fea or weight reduction and finished the
				// process
				if (SolutionFea?.status === FeaStatus.awaitingAnalysis) {
					//the user finished fea process, show him fea alert
					showMechanicalTab = true
					showFeaAnalysisAlert = true
					dispatch({
						type: SOLUTION_PRESSED,
						payload: { id: configuration.id, show: true }
					})
					const feaAnalysisResultsResponse = await getFeaAnalysisResults(
						problemHash as string
					)
					feaAnalysisResults =
						feaAnalysisResultsResponse?.data?.feaAnalysisResults
				} else if (
					SolutionFea?.result === FeaResult.failed &&
					preparingWeightReduction
				) {
					//fea failed and we came from WR
					//change the part weight status to  suitable for weight reduction, in
					// the server and in the client
					await updatePartWeightReductionType(
						part.id,
						WeightReductionType.SUITABLE_FOR_WR
					)
					part.weightReductionType = WeightReductionType.SUITABLE_FOR_WR
				} else if (preparingWeightReduction) {
					// the user finished weight reduction process, not from fea
					await handleWeightReductionState(configuration.id, dispatch)
				}
			}

			const showWeightReductionButton =
				solutionAnalysisService.toShowWeightReductionButton(
					configuration?.type,
					configuration?.result,
					part
				)

			const disableRibbonInfo = Feature.isFeatureOn(
				FeatureComponentId.DISABLE_RIBBON_INFORMATION
			)

			const { effectiveQuantity } = findEffectivePoints(
				configuration,
				undefined,
				drawingCostPercentage
			)

			dispatch({
				type: SOLUTION_FETCHED,
				payload: {
					filters,
					configuration,
					id: configuration.id,
					...colors,
					resultTitle,
					configurationResult,
					resultBody,
					solutionName,
					newSolution: configuration.newSolution,
					solutionPriorities,
					postProcessToggles,
					PostProcessOnCounter,
					prioritiesToggles,
					configurationId: configuration.id,
					leadingByUserChoice: configuration.leadingByUserChoice,
					solution,
					costBenefit,
					costDetails,
					timeBenefit,
					initialSolutionBatchSize,
					materialTypesList,
					amMaterialTypesList,
					categoriesList,
					amCategoriesList,
					trayOrientationCustom,
					materialsList,
					amSubCategories,
					initialMaterial: initMaterial,
					chosenMaterial: initMaterial,
					initialSubCategory: {
						subCategory: initSubCategory,
						type: initialAmMaterialType,
						category: initialAmMaterialCategory,
						chosenMaterialId: initAmMaterialId || ''
					},
					chosenSubCategory: {
						subCategory: initSubCategory,
						type: initialAmMaterialType,
						category: initialAmMaterialCategory,
						chosenMaterialId: initAmMaterialId || ''
					},
					chosenMaterialType: initialMaterialType,
					chosenAmMaterialType: initialAmMaterialType,
					chosenMaterialCategory: initialMaterialCategory,
					chosenAmMaterialCategory: initialAmMaterialCategory,
					part,
					cluster,
					initialSelectedTab: solutionAnalysisService.getInitialSelectedTab(
						solution,
						showMechanicalTab
					),
					...costData,
					analysisResultsRows,
					failedPrintIssuesIds,
					manufacturingMethod,
					chosenOrientationVector: solutionOrientationVector,
					alternativeSolutions,
					alternativeSolutionsPrintersList,
					disableConfiguration,
					customConfiguration,
					...solutionAnalysisService.getSimpleConfigurationData(
						configuration,
						simpleConfigurationSelectorService
					),
					...solutionAnalysisService.getFeaData(
						solution,
						{ ...SolutionFea },
						feaAnalysisResults
					),
					chosenOrientation,
					showFeaAnalysisAlert,
					showWeightReductionButton,
					freezeConfiguration: isWeightReductionPart,
					disableRibbonInfo,
					disablePart,
					effectiveQuantity,
					chartLeadData: leadTimeData.chartData,
					configurationOrganizationSettings:
						allConfigurationsOrganizationSettings?.[
							configuration.organizationId
						],
					isAmOriginalMaterial,
					usedOrganization
				}
			})
			const postProcessesIdsWithAbnormalValues =
				getPostProcessIdsWithAbnormalValues(solution)

			if (
				solution?.isAbnormalPrice ||
				configuration?.traditionalCostIsAbnormal ||
				postProcessesIdsWithAbnormalValues?.length
			) {
				dispatch(
					showCustomFunctionWarningPopup(postProcessesIdsWithAbnormalValues)
				)
			}
		}
	},
	onSolutionClick = ({
		id,
		show,
		tourConfigurationId,
		showConfigure
	}: {
		id: number
		show: boolean
		tourConfigurationId: number | undefined
		showConfigure: boolean
	}): any => {
		return (dispatch: any) => {
			dispatch({
				type: SOLUTION_PRESSED,
				payload: { id, show }
			})
			if (id === tourConfigurationId) {
				setTimeout(() => {
					dispatch({
						type: SOLUTION_TOUR_STEP_UPDATED,
						payload: { id, tourStepIndex: !show ? 0 : showConfigure ? 6 : 1 }
					})
				}, 400)
			}
		}
	},
	onAlternativeSolutionChange = (
		alternativeSolutionId: number,
		alternativeSolutions: Array<any>,
		configuration: any
	): any => {
		return async (dispatch: any) => {
			const prevSolutionId = configuration?.solution?.id
			const solution = alternativeSolutions.find(
				(solution: any) => solution.id === alternativeSolutionId
			)

			const configurationId = configuration.id

			const configurationResult =
				solutionAnalysisService.getSolutionResult(true)
			const resultBody = solutionAnalysisService.getSolutionResultBody(true)
			store.dispatch({
				type: SOLUTION_ANALYSIS_PART_DISABLED,
				payload: {
					id: configurationId,
					configurationResult,
					resultBody
				}
			})

			const res = await switchAlternativeSolution(
				configurationId,
				alternativeSolutionId,
				configuration?.part?.id
			)
			configuration = res?.data?.configuration
			const manufacturingMethod: string =
				manufacturingMethodTypes[
					solutionAnalysisService.getManufacturingMethod(configuration)
				]

			const { partFeas } = res?.data
			dispatch({
				type: PART_FEA_ANALYSIS_DATA_FETCHED,
				payload: {
					partFeas
				}
			})
			dispatch({
				type: FEA_ANALYSIS_DATA_RESET,
				payload: {
					id: configurationId
				}
			})

			dispatch({
				type: ALTERNATIVE_SOLUTION_ADDED,
				payload: {
					id: configurationId,
					solution: solution || configuration?.solution,
					prevSolutionId
				}
			})

			dispatch(
				onWallThicknessConfirm(
					configurationId,
					configuration?.wallThicknessTestInMM,
					solution || configuration?.solution,
					configuration?.part,
					configuration,
					manufacturingMethod,
					true
				)
			)
		}
	},
	onInputFocusOut = (
		id: number,
		solutionName: string,
		configurationName: string,
		isSimpleConfiguration: boolean
	): any => {
		return async (dispatch: any) => {
			dispatch({
				type: SOLUTION_NAME_FOCUSED_OUT,
				payload: { id }
			})
			dispatch({
				type: UPDATE_CONFIGURATION_NAME_TOGGLED
			})
			try {
				if (id > 0 && solutionName && solutionName !== configurationName) {
					await updateSolutionName(id, solutionName, isSimpleConfiguration)
					dispatch({
						type: SOLUTION_NAME_UPDATED,
						payload: { id, solutionName }
					})
				}
			} catch (error: any) {
				console.error(error.message)
			} finally {
				dispatch({
					type: UPDATE_CONFIGURATION_NAME_TOGGLED
				})
			}
		}
	},
	onInputFocusIn = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_NAME_FOCUSED_IN,
			payload: { id }
		}
	},
	onConfigureClick = (
		id: number,
		tourConfigurationId: number | undefined,
		showConfigure: boolean
	): any => {
		return (dispatch: any) => {
			if (id === tourConfigurationId) {
				// update tour index step
				setTimeout(() => {
					dispatch({
						type: SOLUTION_TOUR_STEP_UPDATED,
						payload: { id, tourStepIndex: showConfigure ? 1 : 6 }
					})
				}, 350)
			}
			dispatch({
				type: SOLUTION_CONFIGURE_CLICKED,
				payload: { id }
			})
		}
	},
	onConfigureCancelClick = (
		callbackURL: string,
		quantityChanged: boolean
	): any => {
		return (dispatch: any) => {
			if (quantityChanged) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						alertClass: 'indent-modal',
						headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
						text: getString('CONFIGURATION_CHANGES_HAS_NOT_BE_SAVED'),
						alertType: AlertType.WARNING,
						onConfirm: () => {
							window.location.replace(callbackURL)
						},
						confirmText: getString('OK'),
						showCancel: true
					}
				})
			} else {
				window.location.replace(callbackURL)
			}
		}
	},
	onRetrieveResultClick = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_RETRIEVE_RESULTS_CLICKED,
			payload: { id }
		}
	},
	onConfigureBackClick = (id: number): ActionWithPayload<any> => {
		if (!id) {
			return {
				type: SOLUTION_CONFIGURE_CANCELLED,
				payload: { id }
			}
		}
		if (id === -1) {
			return {
				type: SOLUTION_PRINTER_MATERIAL_CONFIGURE_CANCELLED,
				payload: { id }
			}
		}
		return {
			type: SOLUTION_CONFIGURE_BACK_CLICKED,
			payload: { id }
		}
	},
	onResetClick = (
		id: number,
		configuration: any,
		priorities: IPriority[],
		initialMaterial: any,
		materials: any[],
		originalPostProcessValues: any,
		solution: any,
		project: any,
		materialCategories: any[]
	): ActionWithPayload<any> => {
		const postProcessToggles: any =
			solutionAnalysisService.setupSolutionPostPostProcessToggles(
				configuration.id,
				project?.toleranceIncluded,
				originalPostProcessValues,
				solution
			)

		const prioritiesToggles: any =
			solutionAnalysisService.setupSolutionPrioritiesToggles(
				configuration.priorities,
				priorities
			)

		const solutionPriorities: Map<string, number> =
			solutionAnalysisService.setupSolutionPriorities(
				configuration.initialPriorities || configuration.priorities,
				priorities
			)

		materialsService = new MaterialSelectorService(
			materials,
			initialMaterial?.type,
			initialMaterial?.category,
			initialMaterial,
			materialCategories
		)
		const { materialTypesList, categoriesList, materialsList } =
			materialsService.getDataList()

		const { amMaterialTypesList, amCategoriesList, amSubCategories } =
			amMaterialsService.getDataList()

		const PostProcessOnCounter =
			solutionAnalysisService.howManyPostProcessesOnTempToggle(
				originalPostProcessValues
			)
		return {
			type: SOLUTION_CONFIGURE_RESET_CLICKED,
			payload: {
				id,
				postProcessToggles,
				prioritiesToggles,
				solutionPriorities,
				materialTypesList,
				amMaterialTypesList,
				categoriesList,
				amCategoriesList,
				materialsList,
				amSubCategories,
				PostProcessOnCounter,
				...solutionAnalysisService.getSimpleConfigurationData(
					configuration,
					simpleConfigurationSelectorService
				)
			}
		}
	},
	onPrioritizeFieldChange = (
		id: number,
		priorityName: string,
		priorityValue: number,
		solutionPriorities: Map<string, number>
	): ActionWithPayload<any> => {
		const newSolutionPriorities: Map<string, number> =
			solutionAnalysisService.calculateNewSolutionPriorities(
				solutionPriorities,
				priorityName,
				priorityValue
			)
		return {
			type: SOLUTION_CONFIGURE_PRIORITIZE_CHANGED,
			payload: {
				id,
				solutionPriorities: newSolutionPriorities
			}
		}
	},
	onSolutionNameChange = (
		id: number,
		solutionName: string
	): ActionWithPayload<any> => {
		let tmpSolutionName = ''
		if (solutionName.includes(' ')) {
			const configurationNameArr = solutionName.split(' ')
			configurationNameArr.forEach((word: string) => {
				tmpSolutionName = isEmpty(tmpSolutionName)
					? `${word.charAt(0).toUpperCase()}${word.slice(1)}`
					: `${tmpSolutionName} ${word.charAt(0).toUpperCase()}${word.slice(1)}`
			})
		}
		return {
			type: SOLUTION_NAME_CHANGED,
			payload: {
				id,
				solutionName: isEmpty(tmpSolutionName) ? solutionName : tmpSolutionName
			}
		}
	},
	onCalculateClick = (
		id: number,
		oldSolution: any,
		partId: number | null,
		projectId: string | null,
		priorities: any,
		filters: any,
		filtersErrors: any,
		solutionName: string,
		solutionId: number,
		material: any,
		subCategoryData: any,
		batchSize: number,
		updatedPostProcesses: any,
		allOptionalPostProcessData: any,
		optionalPostProcessesBreakDown: any,
		optionalPostProcessAvailability: any,
		toleranceIncluded: boolean,
		clusterId: number | null,
		isFromPostProcessPopUp: boolean = false,
		part: Part,
		userCurrencySign: string,
		printCostQuantity: number,
		isAmOriginalMaterial: boolean,
		printingOrientationVector?: Array<number>,
		printingOrientationCalc?: boolean,
		orientationChange?: boolean,
		isSimpleConfiguration?: boolean,
		chosenPrinter?: any,
		chosenMaterial?: any,
		isSimpleInhouseConfiguration?: boolean,
		newSupportVolume?: number,
		tourConfigurationId?: number,
		calculationType?: CalculationType,
		organizationId?: number
	): any => {
		if (isAmOriginalMaterial && subCategoryData) {
			if (!filters) {
				filters = {}
			}
			filters.printerMaterialSubCategory = [
				subCategoryData?.subCategory || subCategoryData
			]
			if (subCategoryData.printerMaterialID) {
				filters.printerMaterialID = subCategoryData.printerMaterialID
			}
		} else {
			subCategoryData = null
		}
		return async (dispatch: any) => {
			let disableTabUpdate
			const differentMaterial =
				material?.type !== chosenMaterial?.type ||
				material?.name !== chosenMaterial?.name

			try {
				if (!chosenPrinter || chosenPrinter === '') {
					chosenPrinter = oldSolution?.printer
				}
				if (!chosenMaterial || chosenMaterial === '') {
					chosenMaterial = oldSolution?.printerMaterial
				}
				if (differentMaterial) {
					disableTabUpdate = true
				}
				if (
					!orientationChange &&
					!isFromPostProcessPopUp &&
					clusterId != null &&
					differentMaterial
				) {
					dispatch({
						type: SOLUTION_CONFIGURATION_WAS_CHANGED,
						payload: {
							projectId: part.projectId,
							recalculateClusters: true
						}
					})
				}

				if (isSimpleConfiguration && !material) {
					dispatch({
						type: ALERT_POPPED,
						payload: {
							text: getString(
								'ADD_PRINTER_MATERIAL_CONFIGURATION_WARNING_BODY'
							),
							headerTitle: getString(
								'ADD_PRINTER_MATERIAL_CONFIGURATION_WARNING_TITLE'
							),
							alertType: AlertType.WARNING,
							onConfirm: () => {
								dispatch({ type: ALERT_POPUP_CANCELED })
							},
							showCancel: false,
							confirmText: OK
						}
					})
				} else {
					dispatch({
						type: SOLUTION_CONFIGURE_CALCULATION_CLICKED,
						payload: {
							id,
							popUpCalculation: isFromPostProcessPopUp,
							loadingPrintingOrientationAlert: printingOrientationCalc
						}
					})
					if (tourConfigurationId === id) {
						dispatch({
							type: TOUR_PAUSE_TOGGLED
						})
					}
					let printingOrientationRes = undefined
					let solutionChanged = false
					const response = await createOrUpdateSolution(
						solutionId,
						partId,
						projectId,
						priorities,
						filters,
						id,
						!!part?.isSpecifiedQuantity,
						solutionName,
						material,
						subCategoryData,
						batchSize,
						updatedPostProcesses,
						clusterId,
						printingOrientationVector,
						orientationChange,
						isSimpleConfiguration,
						chosenPrinter,
						chosenMaterial,
						isSimpleInhouseConfiguration,
						newSupportVolume,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						calculationType,
						organizationId
					)

					if (!response) {
						console.error(
							`Failed to try to create or update the solution fot project id: ${projectId}, part id ${partId}.`
						)
						dispatch({
							type: SOLUTION_CONFIGURE_CALCULATION_FAILED,
							payload: {
								id,
								popUpCalculation: isFromPostProcessPopUp
							}
						})
						dispatch({
							type: HANDLE_NOTIFICATION,
							payload: {
								notificationType: SHOW_NOTIFICATION.ERROR,
								notificationMessage: CONFIGURATION_CALCULATION_ERROR
							}
						})
						return
					}

					let { configuration, solution, partPrintIssues } = response?.data

					const {
						user: { defaultSettings },
						MainPartAnalysisReducer: {
							allConfigurationsOrganizationSettings,
							drawingCostPercentage: drawingPartPercentage
						}
					} = store.getState()

					const customizationSettings =
						allConfigurationsOrganizationSettings?.[
							configuration.organizationId
						]?.customizationSettings || defaultSettings

					const drawingCostPercentage =
						customizationSettings.drawingCostPercentage || drawingPartPercentage

					const usedOrganization = getUsedOrganization(configuration, part)

					const postProcessesIdsWithAbnormalValues =
						getPostProcessIdsWithAbnormalValues(solution)

					if (
						solution?.isAbnormalPrice ||
						configuration?.traditionalCostIsAbnormal ||
						postProcessesIdsWithAbnormalValues?.length
					) {
						dispatch(
							showCustomFunctionWarningPopup(postProcessesIdsWithAbnormalValues)
						)
					}

					// update configuration content section
					dispatch(
						setupAdvancedFilters(
							`${configuration.id}_content`,
							false,
							configuration.filters,
							configuration.organizationId
						)
					)

					//Only for lead time testing
					printLeadTimeBreakDown(
						configuration?.name || '',
						configuration?.leadTimeResults?.leadTimeDetails
							?.printingLeadTimeBreakDown || {},
						solution?.leadTimeDetails?.printingLeadTimeBreakDown || {}
					)

					const alternativeSolutions = configuration?.alternativeSolutions || []

					if (solution) {
						solutionChanged =
							solutionAnalysisService.didConfigurationSolutionChanged(
								oldSolution,
								solution
							)
					}

					let configurations =
						store.getState().MainPartAnalysisReducer.configurations

					const res = await solutionAnalysisService.startConditionPoller(
						configuration,
						configuration.id,
						configurations
					)

					if (res?.data) {
						configuration = res.data.configuration
						solution = res.data.solution
						partPrintIssues = res.data.partPrintIssues
					}
					if (printingOrientationCalc) {
						printingOrientationRes = await getPrintingOrientation(
							partId,
							configuration.id,
							solution?.id,
							batchSize,
							updatedPostProcesses
						)

						const { orientationData, chosenOrientationVector } =
							printingOrientationRes?.data
						let defaultOrientation = '0'
						if (orientationData) {
							defaultOrientation =
								solutionAnalysisService.getChosenOrientationVector(
									orientationData,
									chosenOrientationVector
								)
						}

						dispatch({
							type: SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
							payload: {
								orientationData,
								id,
								defaultOrientation
							}
						})
					}
					let trayOrientationCustom =
						solutionAnalysisService.isOrientationCustom(configuration, solution)

					const trayOrientationVector: number[] =
						configuration?.trayOrientation?.trayNormalVector ||
						solution?.trayOrientationVector ||
						[]

					let chosenOrientation = solutionAnalysisService.getChosenOrientation(
						part?.trayOrientations?.data || part?.trayOrientations || [],
						trayOrientationVector
					)

					const projectPdfResponse: any = await getProjectPDFOptions(
						configuration.partId
					)
					const exportPdfOptions =
						projectPdfResponse?.data?.exportPdfOptions || ''
					if (exportPdfOptions) {
						Object.keys(exportPdfOptions).forEach(key => {
							exportPdfOptions[key] = JSON.parse(exportPdfOptions[key])
						})
						dispatch({
							type: PROJECT_PDF_OPTIONS_SUCCESS,
							payload: {
								projectPdfOptions: exportPdfOptions
							}
						})
					}

					if (solution && solution.trayOrientationVector) {
						const ZIndex = solution.trayOrientationVector.findIndex(
							(val: number) => val === 1
						)
						trayOrientationCustom = ZIndex !== 2
					}
					let costData: CostData = new CostData()
					let leadTimeData: LeadData = new LeadData()

					let costBenefit: any = null
					let timeBenefit: any = null
					let costDetails: any = null
					let PostProcessOnCounter: number = 0

					if (id > 0) {
						const manufacturingMethod: string =
							manufacturingMethodTypes[
								solutionAnalysisService.getManufacturingMethod(configuration)
							]
						if (configuration.costResults) {
							costData = costComparisonService.getCostData(
								configuration.costResults,
								manufacturingMethod,
								userCurrencySign,
								solution?.costDetails?.totalCost || 0,
								isAmOriginalMaterial,
								drawingCostPercentage,
								configuration.part?.isDrawing ||
									configuration.part?.formatType === METADATA,
								configuration.part?.blockManufacturingMethodOperation,
								configuration?.isSpecifiedQuantity || false
							)
						}

						if (configuration.leadTimeResults) {
							leadTimeData = leadTimeService.getLeadData(
								configuration.leadTimeResults,
								manufacturingMethod,
								configuration.unlockLeadTimeGraph,
								configuration.quantity,
								!!part?.standardCost && !configuration.standardCost,
								configuration,
								isAmOriginalMaterial
							)
						}

						const postProcessToggles: any =
							solutionAnalysisService.setupSolutionPostPostProcessToggles(
								configuration.id,
								toleranceIncluded,
								configuration?.postProcessesOptional,
								solution
							)
						const showWeightReductionButton =
							solutionAnalysisService.toShowWeightReductionButton(
								configuration?.type,
								configuration?.result,
								part
							)
						if (solution) {
							costBenefit = solutionAnalysisService.getCostBenefit(solution)
							timeBenefit = solutionAnalysisService.getTimeBenefit(solution)
							costDetails =
								solutionAnalysisService.createCostDetailsPostProcessesLabels(
									configuration?.postProcessesOptional,
									solution?.costDetails
								)

							PostProcessOnCounter =
								solutionAnalysisService.howManyPostProcessesOn(
									postProcessToggles
								)
						}
						const configurationPrintIssues: PartPrintIssue[] =
							partPrintIssues.filter(
								(partPrintIssue: PartPrintIssue) =>
									partPrintIssue.configurationId === configuration.id ||
									!partPrintIssue.configurationId
							)
						const analysisResultsRows =
							solutionAnalysisService.getAnalysisResultsRows(
								solution,
								configuration,
								configurationPrintIssues,
								trayOrientationVector,
								part?.isDrawing,
								part?.formatType === METADATA
							)
						const failedPrintIssuesIds =
							solutionAnalysisService.getFailedPrintIssues(analysisResultsRows)

						const { effectiveQuantity } = findEffectivePoints(
							configuration,
							undefined,
							drawingCostPercentage
						)

						dispatch({
							type: GET_ALTERNATIVE_SOLUTION_CALCULATED,
							payload: {
								solutions: alternativeSolutions,
								drawingCostPercentage
							}
						})

						dispatch({
							type: MAIN_PART_PART_PRINT_ISSUES_UPDATED,
							payload: {
								partPrintIssues
							}
						})

						dispatch({
							type: SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
							payload: {
								id,
								configuration,
								costBenefit,
								costDetails,
								timeBenefit,
								solution,
								optionalPostProcessAvailability,
								allOptionalPostProcessData,
								optionalPostProcessesBreakDown,
								postProcessToggles,
								PostProcessOnCounter,
								chosenMaterial: material,
								trayOrientationCustom,
								initialSelectedTab:
									solutionAnalysisService.getInitialSelectedTab(solution),
								...costData,
								analysisResultsRows,
								failedPrintIssuesIds,
								manufacturingMethod,
								printingOrientationCalc,
								alternativeSolutions,
								chosenOrientation,
								chosenOrientationVector: trayOrientationVector,
								showWeightReductionButton,
								effectiveQuantity,
								disableTabUpdate,
								chartLeadData: leadTimeData.chartData,
								tabIndex: tabOrder.costAnalysis,
								usedOrganization
							}
						})

						if (printingOrientationCalc && printingOrientationRes) {
							const { orientationData = null } = printingOrientationRes?.data
							dispatch({
								type: SOLUTION_CONFIGURE_SHOW_ORIENTATION_PRINTING_MODEL,
								payload: { id, orientationData }
							})
							if (solutionChanged && solution) {
								dispatch({
									type: HANDLE_NOTIFICATION,
									payload: {
										notificationType: SHOW_NOTIFICATION.INFO,
										notificationMessage: `${solution.printer?.name} and ${solution.printerMaterial?.name} where selected for ${configuration.name}. `
									}
								})
							}

							solutionAnalysisService.createOrientationsAnalysisObjectsForTable(
								orientationData,
								configuration,
								solution,
								configurationPrintIssues
							)
						} else {
							dispatch({
								type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CONFIRM,
								payload: { id }
							})
							if (orientationChange) {
								dispatch({
									type: HANDLE_NOTIFICATION,
									payload: {
										notificationType: SHOW_NOTIFICATION.INFO,
										notificationMessage: ORIENTATION_CHANGED_MSG
									}
								})
							}
						}
						// The code beneath is a plaster for the 'react-apexChart' rerender
						// xAxis bug. issue:
						// https://github.com/apexcharts/react-apexcharts/issues/94
						dispatch({
							type: COST_COMPARISON_EDIT_CLICKED,
							payload: {
								id
							}
						})
						dispatch({
							type: COST_COMPARISON_FORM_CANCELLED,
							payload: {
								id
							}
						})
						dispatch({
							type: COST_COMPARISON_RESET_CHAIN_BENEFITS,
							payload: {
								id
							}
						})
						if (isFromPostProcessPopUp && solution) {
							const PostProcessOnCounter =
								solutionAnalysisService.howManyPostProcessesOnTempToggle(
									postProcessToggles
								)
							dispatch({
								type: SOLUTION_CONFIGURE_POST_PROCESSES_MODAL_CONFIRMED,
								payload: { id, PostProcessOnCounter, postProcessToggles }
							})
						}
					} else {
						configurations = configurations.concat([configuration])
						const solutionName =
							solutionAnalysisService.getSolutionName(oldSolution)
						dispatch({
							type: NEW_SOLUTION_CONFIGURATION_ADDED,
							payload: {
								id,
								configuration,
								solution,
								solutionName,
								partPrintIssues
							}
						})
					}
					dispatch({
						type: NOTIFICATION_CLEARED
					})

					res &&
						res?.data &&
						dispatch({
							type: HANDLE_NOTIFICATION,
							payload: {
								notificationType: SHOW_NOTIFICATION.SUCCESS,
								notificationMessage: getString(
									'CONFIGURATION_CREATED_OR_UPDATED_SUCCESFULLY'
								)
							}
						})
					solutionAnalysisService.dispatchUpdatingPartUI(configurations, id)

					dispatch({
						type: CONFIGURATION_CALCULATED
					})
				}
				if (id === tourConfigurationId) {
					setTimeout(() => {
						dispatch({
							type: SOLUTION_TOUR_STEP_UPDATED,
							payload: {
								id,
								tourStepIndex: 9,
								pauseTour: false
							}
						})
					}, 350)
				}
			} catch (error: any) {
				dispatch({
					type: SOLUTION_CONFIGURE_CALCULATION_FAILED,
					payload: {
						id,
						popUpCalculation: isFromPostProcessPopUp,
						failed: true
					}
				})
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.message || CONFIGURATION_CALCULATION_ERROR
					}
				})
				console.error(error.message)
				dispatch({
					type: MAIN_PART_ANALYSIS_DISABLE_PART_TOGGLED,
					payload: {
						updating: false
					}
				})
			}
		}
	},
	onRemoveApprove = (
		configurationId: number,
		isSimpleConfiguration: boolean,
		callBackFunction?: Function
	): any => {
		return async (dispatch: any) => {
			try {
				const {
					user: { defaultSettings },
					MainPartAnalysisReducer: { allConfigurationsOrganizationSettings }
				} = store.getState()

				dispatch({
					type: SOLUTION_START_REMOVED
				})
				const response = await removeSolution(
					configurationId,
					isSimpleConfiguration
				)
				const { configurations } = response?.data

				const alternativeSolutionsConfig = configurations.find(
					(config: IConfiguration) => config.alternativeSolutions?.length > 0
				)

				const drawingCostPercentage =
					allConfigurationsOrganizationSettings[
						alternativeSolutionsConfig?.organizationId
					]?.customizationSettings?.drawingCostPercentage ||
					defaultSettings?.drawingCostPercentage

				dispatch({
					type: SOLUTION_REMOVED,
					payload: {
						id: configurationId,
						configurations,
						drawingCostPercentage
					}
				})
				dispatch({
					type: CONFIGURATION_CALCULATED
				})
				dispatch({
					type: SOLUTION_FINISH_REMOVED
				})

				if (callBackFunction) {
					callBackFunction()
				}
			} catch (error: any) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.message || CONFIGURATION_CALCULATION_ERROR
					}
				})
				dispatch({
					type: SOLUTION_FINISH_REMOVED
				})
				console.error(error.message)
			}
		}
	},
	onPostProcessesToggleChange = (
		id: number,
		postProcessToggled: any,
		infoMessage: string,
		toggleState: boolean
	): any => {
		return (dispatch: any) => {
			dispatch({
				type: SOLUTION_CONFIGURE_POST_PROCESSES_TOGGLED,
				payload: { id, postProcessToggled, infoMessage }
			})

			if (!toggleState) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: infoMessage
					}
				})
			}
		}
	},
	onPriorityToggleChange = (
		id: number,
		priority: IPriority
	): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_PRIORITY_TOGGLED,
			payload: { id, priority }
		}
	},
	onPriorityRemove = (
		id: number,
		priority: IPriority,
		solutionPriorities: any
	): ActionWithPayload<any> => {
		const newSolutionPriorities: Map<string, number> =
			solutionAnalysisService.calculateNewSolutionPriorities(
				solutionPriorities,
				priority.name,
				0
			)
		return {
			type: SOLUTION_CONFIGURE_PRIORITY_REMOVED,
			payload: { id, priority, solutionPriorities: newSolutionPriorities }
		}
	},
	onFilterModalCancel = (configuration: any): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_FILTER_MODAL_CANCELED,
			payload: { id: configuration.id, configuration }
		}
	},
	onFilterModalConfirm = (id: number, filters: any): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_FILTER_MODAL_CONFIRMED,
			payload: { id, filters }
		}
	},
	onTrayOrientationChange = (
		chosenOrientationVector: Array<number>,
		orientationIsDefault: boolean,
		newSupportVolume: number,
		warning: boolean,
		configuration: any,
		failOnSize: boolean
	): any => {
		return (dispatch: any) => {
			if (warning) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.WARN,
						notificationMessage: TRAY_ORIENTATION_RESULT_WARNING
					}
				})
			}
			if (failOnSize != null && failOnSize) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.WARN,
						notificationMessage: ORIENTATION_FAIL_ON_SIZE_MSG
					}
				})
				return
			}
			if (!orientationIsDefault) {
				dispatch({
					type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE,
					payload: {
						id: configuration.id,
						chosenOrientationVector,
						newSupportVolume,
						trayOrientationCustom: true
					}
				})
			} else {
				dispatch({
					type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_CHANGE,
					payload: {
						id: configuration.id,
						chosenOrientationVector,
						newSupportVolume,
						trayOrientationCustom: false
					}
				})
			}
		}
	},
	onPostProcessesModalCancel = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_POST_PROCESSES_CANCELED,
			payload: { id }
		}
	},
	onChangeOrientationCancel = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_POP_UP_CANCELED,
			payload: { id }
		}
	},
	onPrintingOrientationAlertCancel = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CANCELED,
			payload: { id }
		}
	},
	onPrintingOrientationAlertConfirm = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_ORIENTATION_PRINTING_ALERT_CONFIRM,
			payload: { id }
		}
	},
	onPostProcessesClick = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_POST_PROCESSES_FEATURE_CLICKED,
			payload: { id }
		}
	},
	onTrayOrientationResponse = (printingOrientationRes: any, id: number) => {
		const { orientationData = null } = printingOrientationRes?.data
		return {
			type: SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
			payload: { orientationData, id }
		}
	},
	onChangeOrientationClick = (
		id: number,
		partSolution: any,
		batchSize: number,
		configurationChanged: boolean,
		updatedPostProcess: any,
		part: Part | null,
		configuration: any,
		configurationPrintIssues: PartPrintIssue[],
		isMechanicalAnalysisCall?: boolean
	): any => {
		return async (dispatch: any) => {
			if (id <= 0) {
				return dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.WARN,
						notificationMessage: PRINTING_ORIENTATION_NEW_CONF_MSG
					}
				})
			}
			if (!partSolution) {
				return dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('PRINTING_ORIENTATION_UNPRINTABLE_MSG'),
						alertType: AlertType.WARNING,
						headerTitle: getString('PRINTING_ORIENTATION_ALERT_HEADER'),
						onConfirm: () => {
							dispatch({ type: ALERT_POPUP_CANCELED })
						},
						showCancel: false,
						confirmText: getString('OK')
					}
				})
			}
			dispatch({
				type: START_POLLING_TRAY_ORIENTATION_DATA,
				payload: { id, trayOrientationLoader: false }
			})
			if (configurationChanged) {
				dispatch({
					type: SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
					payload: { id, configurationChanged }
				})
			} else {
				try {
					dispatch({
						type: SOLUTION_ORIENTATION_CLICKED,
						payload: { id, loading: true }
					})
					const partId: number = partSolution?.part?.id || partSolution?.partId
					const partSolutionId: number = partSolution.id
					const response = await getPrintingOrientation(
						partId,
						id,
						partSolutionId,
						batchSize,
						updatedPostProcess
					)
					const { orientationData, chosenOrientationVector } = response?.data
					let defaultOrientation = '0'
					if (orientationData) {
						defaultOrientation =
							solutionAnalysisService.getChosenOrientationVector(
								orientationData,
								chosenOrientationVector
							)
						solutionAnalysisService.createOrientationsAnalysisObjectsForTable(
							orientationData,
							configuration,
							partSolution,
							configurationPrintIssues
						)
					}
					dispatch({
						type: SOLUTION_CONFIGURE_CHANGE_ORIENTATION_FEATURE_CLICKED,
						payload: {
							orientationData,
							id,
							configurationChanged,
							defaultOrientation,
							isMechanicalAnalysisCall
						}
					})
					dispatch({
						type: SOLUTION_ORIENTATION_CLICKED,
						payload: { id, loading: false }
					})
				} catch (error: any) {
					dispatch({
						type: SOLUTION_ORIENTATION_CLICKED,
						payload: { id, loading: false }
					})
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.ERROR,
							notificationMessage:
								error.message || CONFIGURATION_CALCULATION_ERROR
						}
					})
					dispatch({
						type: END_POLLING_TRAY_ORIENTATION_DATA,
						payload: { id }
					})
					console.error(error.message)
				}
			}
		}
	},
	onFilterFeatureClick = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_FILTER_FEATURE_CLICKED,
			payload: { id }
		}
	},
	onPrioritiesFeatureClick = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_PRIORITIZE_FEATURE_CLICKED,
			payload: { id }
		}
	},
	onPrioritiesModalCancel = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CANCELED,
			payload: { id }
		}
	},
	onPrioritiesModalConfirm = (
		id: number,
		priorities: IPriority[],
		tempSolutionPrioritiesToggles: any,
		solutionPriorities: Map<string, number>
	): ActionWithPayload<any> => {
		const newSolutionPriorities = solutionAnalysisService.addSolutionPriorities(
			priorities,
			tempSolutionPrioritiesToggles,
			solutionPriorities
		)
		return {
			type: SOLUTION_CONFIGURE_PRIORITIZE_MODAL_CONFIRMED,
			payload: { id, solutionPriorities: newSolutionPriorities }
		}
	},
	formatComponent = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_UNMOUNTED,
			payload: { id }
		}
	},
	resetSolution = (id: number): ActionWithPayload<any> => {
		return {
			type: SOLUTION_CONFIGURE_RESET_CLICKED,
			payload: { id }
		}
	},
	onSolutionSaveClick = (
		configurationId: number,
		starred: boolean,
		isSimpleConfiguration: boolean
	): any => {
		return async (dispatch: any) => {
			try {
				if (configurationId && starred !== undefined) {
					const res = await updateSolutionStarred(
						configurationId,
						starred,
						isSimpleConfiguration
					)
					if (res && res.data) {
						const { configurationUpdated } = res.data
						dispatch({
							type: SOLUTION_STARRED,
							payload: {
								id: configurationId,
								starred: configurationUpdated.starred
							}
						})
					}
				}
			} catch (err) {
				console.log(err)
				throw err
			}
		}
	},
	onSetLeadingConfigurationClick = (
		configurationId: number,
		leadingByUserChoice: boolean
	) => {
		return async (dispatch: any) => {
			try {
				const res = await updateLeadingConfiguration(
					configurationId,
					leadingByUserChoice
				)
				if (res && res.data) {
					const {
						configurationId: leadingConfigurationId,
						leadingByUserChoice
					} = res.data
					dispatch({
						type: CONFIGURATION_IS_SET_LEADING,
						payload: {
							leadingConfigurationId,
							id: configurationId,
							leadingByUserChoice
						}
					})
				}
			} catch (err: any) {
				console.log(err)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: err.message
					}
				})
			}
		}
	},
	onCombineClick = (
		partId: string | null,
		projectId: string | null,
		sameMaterial: boolean
	): any => {
		return async (dispatch: any) => {
			try {
				if (partId && projectId) {
					await combineAroundPart(partId, projectId, sameMaterial)

					dispatch({
						type: ALERT_POPPED,
						payload: {
							text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
							headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
							showCancel: false,
							alertType: AlertType.SUCCESS,
							onConfirm: () => {
								dispatch({
									type: ALERT_POPUP_CANCELED
								})
							},
							confirmText: OK
						}
					})
				}
			} catch (err) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: CONFIGURATION_CALCULATION_ERROR
					}
				})
				console.log(err)
				throw err
			}
		}
	},
	onConfigurationQuoteClick = (configurationId: number) => {
		return async (dispatch: any) => {
			try {
				if (configurationId) {
					await userSendToPrint(configurationId)
				}
			} catch (err) {
				console.log(err)
			}
		}
	},
	onExport3dClick = (
		configurationId: number,
		part: Part,
		userProviders: IUserProvider[]
	) => {
		return async (dispatch: Dispatch<AnyAction>) => {
			try {
				if (configurationId) {
					const response = await exportConfigurationData(
						configurationId,
						part,
						userProviders,
						json
					)
					const signedUrl = response?.data?.signedUrl
					window.open(`DDDXpertLauncher:${signedUrl}`, '_blank')
					dispatch({
						type: EXPORT_3D_LINK_UPDATED,
						payload: {
							id: configurationId,
							signedUrl
						}
					})
				}
			} catch (error: any) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.message || CONFIGURATION_CALCULATION_ERROR
					}
				})
				console.log(error)
			}
		}
	},
	onBatchSizeChange = (id: number, batchSize: number) => {
		return {
			type: BATCH_SIZE_CHANGED,
			payload: {
				id,
				batchSize
			}
		}
	},
	onChangeMaterialType = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
				materialsService.materialTypeChanged(value)
			const { materialTypesList, categoriesList, materialsList } =
				materialsService.getDataList()

			dispatch(solutionMaterialPrioritiesChanged(id, chosenMaterial))

			dispatch({
				type: MATERIAL_TYPE_CHANGED,
				payload: {
					id,
					chosenMaterialType,
					chosenMaterialCategory,
					chosenMaterial,
					materialTypesList,
					categoriesList,
					materialsList
				}
			})
		}
	},
	onChangeAmMaterialType = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const {
				chosenAmMaterialType,
				chosenAmMaterialCategory,
				chosenSubCategory
			} = amMaterialsService.materialTypeChanged(value)
			const { amMaterialTypesList, amCategoriesList, amSubCategories } =
				amMaterialsService.getDataList()

			dispatch({
				type: AM_MATERIAL_TYPE_CHANGED,
				payload: {
					id,
					chosenAmMaterialType,
					chosenAmMaterialCategory,
					chosenSubCategory,
					amMaterialTypesList,
					amCategoriesList,
					amSubCategories
				}
			})
		}
	},
	onChangeMaterialCategory = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
				materialsService.materialCategoryChanged(value)
			const { materialTypesList, categoriesList, materialsList } =
				materialsService.getDataList()

			dispatch(solutionMaterialPrioritiesChanged(id, chosenMaterial))

			dispatch({
				type: MATERIAL_CATEGORY_CHANGED,
				payload: {
					id,
					chosenMaterialType,
					chosenMaterial,
					chosenMaterialCategory,
					materialTypesList,
					categoriesList,
					materialsList
				}
			})
		}
	},
	onChangeAmMaterialCategory = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const { chosenAmMaterialCategory, chosenSubCategory } =
				amMaterialsService.materialCategoryChanged(value)
			const { amSubCategories } = amMaterialsService.getDataList()

			dispatch({
				type: AM_MATERIAL_CATEGORY_CHANGED,
				payload: {
					id,
					chosenAmMaterialCategory,
					chosenSubCategory,
					amSubCategories
				}
			})
		}
	},
	onChangeMaterial = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
				materialsService.materialChanged(value)
			const { materialTypesList, categoriesList, materialsList } =
				materialsService.getDataList()

			dispatch(solutionMaterialPrioritiesChanged(id, chosenMaterial))

			dispatch({
				type: MATERIAL_CHANGED,
				payload: {
					id,
					chosenMaterialType,
					chosenMaterialCategory,
					chosenMaterial,
					materialTypesList,
					categoriesList,
					materialsList
				}
			})
		}
	},
	onChangeAmSubCategory = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const { chosenSubCategory } = amMaterialsService.subCategoryChanged(value)
			dispatch({
				type: AM_SUB_CATEGORY_CHANGED,
				payload: {
					id,
					chosenSubCategory
				}
			})
		}
	},
	onChangeAmMaterial = (value: any, id: number): any => {
		return async (dispatch: Dispatch<AnyAction>) => {
			const { chosenSubCategory } = amMaterialsService.materialChanged(value)
			dispatch({
				type: AM_MATERIAL_CHANGED,
				payload: {
					id,
					chosenSubCategory
				}
			})
		}
	},
	originalFilterButtonClicked = (showMaterialsFilters: boolean, id: number) => {
		return {
			type: MATERIAL_SELECTOR_TOGGLED,
			payload: { id, showMaterialsFilters: !showMaterialsFilters }
		}
	},
	amMaterialFilterButtonClicked = (
		showAmMaterialsFilters: boolean,
		id: number
	) => {
		return {
			type: AM_MATERIAL_SELECTOR_TOGGLED,
			payload: { id, showAmMaterialsFilters: !showAmMaterialsFilters }
		}
	}

export const onCostEditClicked = (id: number): ActionWithPayload<any> => {
	return {
		type: COST_COMPARISON_EDIT_CLICKED,
		payload: { id }
	}
}
export const onLeadTimeEditClicked = (id: number): ActionWithPayload<any> => {
	return {
		type: LEAD_TIME_FORM_EDIT_CLICKED,
		payload: { id }
	}
}
export const chainBenefitsValuesChanged = (
	id: number,
	valueObject: object,
	chainBenefitName: string
): ActionWithPayload<any> => {
	return {
		type: CHAIN_BENEFIT_VALUE_CHANGED,
		payload: { id, valueObject, chainBenefitName }
	}
}
export const onChainBenefitsFormsEditCancelled = (
	id: number
): ActionWithPayload<any> => {
	return {
		type: CHAIN_BENEFITS_FORM_EDIT_CANCELLED,
		payload: { id }
	}
}
export const onCostFormCancelled = (id: number): ActionWithPayload<any> => {
	return {
		type: COST_COMPARISON_FORM_CANCELLED,
		payload: { id }
	}
}
export const onLeadTimeFormCancelled = (id: number): ActionWithPayload<any> => {
	return {
		type: LEAD_TIME_FORM_FORM_CANCELLED,
		payload: { id }
	}
}
export const resetChainBenefits = (id: number): ActionWithPayload<any> => {
	return {
		type: COST_COMPARISON_RESET_CHAIN_BENEFITS,
		payload: { id }
	}
}
export const onMetalCostFormSubmit = (
	partId: number,
	cncProps: IOptionalMetalFormParameters,
	solution: any,
	id: number,
	manufacturingMethod: string,
	batchSize: number,
	postProcessesOptional: any,
	tempChainBenefits: IChainBenefits,
	configurations: Array<any>,
	expectedYearsOfDemand: number,
	chosenOrientation: any,
	isTooling?: boolean,
	selectedProductLifeCycle?: string,
	printJobs?: boolean
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: COST_COMPARISON_EDIT_STARTED,
				payload: {
					id
				}
			})

			if (isBoolean(isTooling)) {
				try {
					await updateToolingParam(id, isTooling)
				} catch (e) {
					dispatch({
						type: TOOLING_TOGGLE_CHANGE_ERROR,
						payload: { id }
					})
				}
			}

			let response = await updateUserCustomCNCProps(cncProps)

			const userCustomizationSettings =
				response?.data?.userCustomizationSettings
			dispatch({
				type: USER_CUSTOM_SETTINGS_UPDATED,
				payload: {
					userCustomizationSettings
				}
			})

			response = await updateCNCProps(partId, cncProps)
			let part = response?.data?.part

			dispatch({
				type: PART_ANALYSIS_METAL_FORM_SUBMITED,
				payload: {
					part
				}
			})

			response = await updateSolutionQuantity(
				id,
				batchSize,
				postProcessesOptional,
				tempChainBenefits,
				expectedYearsOfDemand,
				chosenOrientation,
				false,
				selectedProductLifeCycle,
				printJobs
			)

			dispatch({
				type: LIFE_CYCLE_FORM_SUBMITED,
				payload: {}
			})

			const { configuration, partPrintIssues } = response?.data

			const keepAnalysis = true
			const keepForm = false
			await solutionAnalysisService.updateSolution(
				configuration,
				solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				configuration.alternativeSolutions,
				keepForm,
				keepAnalysis
			)
			const updateConfigurations = configurations.filter(
				(configuration: IConfiguration) => !!configuration.solution
			)

			await updateAllOthersConfigurations(
				updateConfigurations,
				id,
				part,
				dispatch,
				partPrintIssues
			)
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			dispatch({
				type: MAIN_PART_UPDATE_CHAIN_BENEFITS,
				payload: { id, configuration, partPrintIssues }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			console.error(error)
		}
	}
}

export const onCostFormSubmit = (
	data: any,
	batchSize: number,
	partId: number,
	solution: any,
	id: number,
	manufacturingMethod: string,
	postProcessesOptional: any,
	tempChainBenefits: IChainBenefits,
	configurations: Array<any>,
	expectedYearsOfDemand: number,
	chosenOrientation: any,
	isTooling?: boolean,
	selectedProductLifeCycle?: string,
	printJobs?: boolean
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: COST_COMPARISON_EDIT_STARTED,
				payload: {
					id
				}
			})

			if (isBoolean(isTooling)) {
				try {
					await updateToolingParam(id, isTooling)
				} catch (e) {
					dispatch({
						type: TOOLING_TOGGLE_CHANGE_ERROR,
						payload: { id }
					})
				}
			}

			let response = await updateSolutionQuantity(
				id,
				batchSize,
				postProcessesOptional,
				tempChainBenefits,
				expectedYearsOfDemand,
				chosenOrientation,
				false,
				selectedProductLifeCycle,
				printJobs
			)

			dispatch({
				type: LIFE_CYCLE_FORM_SUBMITED,
				payload: {}
			})

			const { configuration, partPrintIssues } = response?.data
			let { part } = configuration
			const chartDataLabels =
				costComparisonService.setChartDataLabels(batchSize)
			const {
				moldCost,
				moldPartCost,
				moldMaintenanceCost,
				DFMCosts = '',
				castToolingCost,
				investmentCastToolingCost,
				puAnnualKgCO2,
				puYearsCO2,
				disposeFactor
			} = data

			response = await updatePartAnalysis(
				partId,
				moldCost,
				moldPartCost,
				moldMaintenanceCost,
				DFMCosts,
				null,
				null,
				null,
				null,
				null,
				null,
				chartDataLabels,
				configuration.quantity,
				solution.cost,
				castToolingCost,
				investmentCastToolingCost,
				puAnnualKgCO2,
				puYearsCO2,
				disposeFactor
			)

			part = response?.data?.part
			dispatch({
				type: PART_ANALYSIS_PLASTIC_FORM_SUBMITED,
				payload: {
					part
				}
			})
			const keepAnalysis = true
			const keepForm = false
			await solutionAnalysisService.updateSolution(
				configuration,
				solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				configuration?.alternativeSolutions,
				keepForm,
				keepAnalysis
			)

			if (part?.clusterId && part.id) {
				dispatch({
					type: DO_REFRESH_CONFIGURATIONS,
					payload: {
						doRefreshConfigurations: true
					}
				})
			} else {
				const updateConfigurations = configurations.filter(
					(configuration: IConfiguration) => !!configuration.solution
				)

				await updateAllOthersConfigurations(
					updateConfigurations,
					id,
					part,
					dispatch,
					partPrintIssues
				)
			}

			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			dispatch({
				type: MAIN_PART_UPDATE_CHAIN_BENEFITS,
				payload: { id, configuration, partPrintIssues }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			console.error(error)
		}
	}
}

export const onLeadTimeFormSubmit = (
	data: any,
	batchSize: number,
	partId: number,
	solution: any,
	id: number,
	manufacturingMethod: string,
	postProcessesOptional: any,
	tempChainBenefits: IChainBenefits,
	configurations: Array<any>,
	expectedYearsOfDemand: number,
	chosenOrientation: any
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: LEAD_TIME_EDIT_STARTED,
				payload: {
					id
				}
			})

			const { tmInitialTechnologySetupTime, removalRateCNC, moq } = data

			// prepare data and make number from string
			let params: any = {
				id: partId,
				tmInitialTechnologySetupTime: +tmInitialTechnologySetupTime,
				moq: +moq,
				// get key form name of manufacturingMethodTypes
				manufactureMethod: invert(manufacturingMethodTypes)[manufacturingMethod]
			}

			if (manufacturingMethod === manufacturingMethodTypes.cnc) {
				params = {
					...params,
					removalRateCNC: +removalRateCNC
				}
			}

			let response = await updateLeadTime(params)

			const part = response?.data?.part

			response = await updateSolutionQuantity(
				id,
				batchSize,
				postProcessesOptional,
				tempChainBenefits,
				expectedYearsOfDemand,
				chosenOrientation
			)

			const { configuration, partPrintIssues } = response?.data
			dispatch({
				type: PART_ANALYSIS_LEAD_FORM_SUBMITED,
				payload: {
					part
				}
			})

			const keepAnalysis = false
			const isLeadTime = true
			const keepForm = false
			const changeQuantity = false

			await solutionAnalysisService.updateSolution(
				configuration,
				solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				configuration?.alternativeSolutions,
				keepForm,
				keepAnalysis,
				changeQuantity,
				isLeadTime
			)

			const updateConfigurations = configurations.filter(
				(configuration: IConfiguration) => !!configuration.solution
			)

			await updateAllOthersConfigurations(
				updateConfigurations,
				id,
				part,
				dispatch,
				partPrintIssues,
				isLeadTime
			)

			dispatch({
				type: LEAD_TIME_EDIT_FINISHED,
				payload: {
					id
				}
			})

			dispatch({
				type: MAIN_PART_UPDATE_CHAIN_BENEFITS,
				payload: { id, configuration, partPrintIssues }
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: LEAD_TIME_EDIT_FINISHED,
				payload: {
					id
				}
			})
			console.error(error)
		}
	}
}
const updateAllOthersConfigurations = async (
	configurations: Array<ISolutionConfiguration>,
	excludeConfigurationId: number | null,
	part: Part,
	dispatch: any,
	partPrintIssues: PartPrintIssue[],
	isLeadTime?: boolean
) => {
	await Promise.all(
		configurations.map(async (configuration: any) => {
			if (
				configuration.id !== -1 &&
				configuration.id !== 0 &&
				configuration.id !== excludeConfigurationId
			) {
				await solutionAnalysisService.updateSolution(
					configuration,
					configuration.solution,
					dispatch,
					getManufacturingMethodName(
						configuration.manufactureMethod,
						configuration.material.type
					),
					partPrintIssues,
					configuration.alternativeSolutions,
					false,
					false,
					false,
					isLeadTime
				)
			}
		})
	)
}

export const onCostQuantityChanged = (
	id: number,
	batchSize: number
): ActionWithPayload<any> => {
	return {
		type: COST_COMPARISON_QUANTITY_CHANGED,
		payload: {
			id,
			batchSize
		}
	}
}

export const onInhouseTogleClick = (): Action<any> => {
	return {
		type: CONFIGURATION_INHOUSE_ALERT_OPENED
	}
}

export const onSimpleInhouseConfigurationChange = (
	id: number,
	printersFullData: Array<ISimpleConfigurationCompany>,
	printers: IPrinter[],
	configuration: any,
	value: boolean
): Action<any> => {
	simpleConfigurationSelectorService = new SimpleConfigurationSelectorService(
		printersFullData,
		value,
		true,
		printers
	)

	return {
		type: SIMPLE_CONFIGURATION_INHOUSE_TOGGLED,
		payload: {
			id,
			inHouseToggle: value,
			...solutionAnalysisService.getSimpleConfigurationData(
				configuration,
				simpleConfigurationSelectorService
			)
		}
	}
}
export const onChainBenefitsCheckBoxChange = (
	id: number,
	chainBenefitName: ChainBenefitsNames,
	chainBenefitStatus: boolean
) => {
	return {
		type: CHAIN_BENEFITS_CHECK_BOX_CHANGED,
		payload: { id, chainBenefitName, chainBenefitStatus }
	}
}
export const onGlobalChainBenefitsCheckBoxChange = (id: number) => {
	return {
		type: GLOBAL_CHAIN_BENEFITS_CHECK_BOX_CHANGED,
		payload: { id }
	}
}

export const onLifeCycleChange = (lifeCycle: string, id: number) => {
	return {
		type: LIFE_CYCLE_CHANGED,
		payload: { lifeCycle, id }
	}
}

export const onLifeCycleChangeFullData = (
	lifeCycle: string,
	manufacturingMethod: string,
	defaultProjectScenarios: any,
	configuration: any
) => {
	const selectedProductLifeCyclePart = defaultProjectScenarios.find(
		(element: any) => element.key === lifeCycle
	)
	const lifeCycleMaintenance = selectedProductLifeCyclePart.Maintenance
	const lifeCycleObsolescence = selectedProductLifeCyclePart.Obsolescence
	const lifeCycleOrdering = selectedProductLifeCyclePart.Ordering

	return async (dispatch: any) => {
		try {
			dispatch(onLifeCycleChange(lifeCycle, configuration.id))
			dispatch(
				onCastToolingToggleChange(
					configuration.id,
					selectedProductLifeCyclePart.isTooling
				)
			)
			dispatch(
				onChainBenefitsYearsOfDemandChange(
					selectedProductLifeCyclePart.expectedYearsOfDemand,
					configuration.id
				)
			)
			dispatch(
				onChainBenefitsCheckBoxChange(
					configuration.id,
					ChainBenefitsNames.Maintenance,
					lifeCycleMaintenance
				)
			)
			dispatch(
				onChainBenefitsCheckBoxChange(
					configuration.id,
					ChainBenefitsNames.Obsolescence,
					lifeCycleObsolescence
				)
			)
			dispatch(
				onChainBenefitsCheckBoxChange(
					configuration.id,
					ChainBenefitsNames.Ordering,
					lifeCycleOrdering
				)
			)

			if (lifeCycleMaintenance || lifeCycleObsolescence || lifeCycleOrdering) {
				dispatch(
					onChainBenefitsCheckBoxChange(
						configuration.id,
						ChainBenefitsNames.Global,
						true
					)
				)
			}

			if (manufacturingMethod === manufacturingMethodTypes.cnc) {
				dispatch(
					onCamExistenceChange(selectedProductLifeCyclePart.camExistence)
				)
			}
		} catch (err) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
			console.log(err)
			throw err
		}
	}
}

export const onChainBenefitsYearsOfDemandChange = (
	expectedYearsOfDemand: number,
	id: number
) => {
	return {
		type: CHAIN_BENEFITS_YEARS_OF_DEMAND_CHANGED,
		payload: { id, expectedYearsOfDemand }
	}
}
export const onOutsourcedCNCManufacturingToggleUpdate = (
	id: number,
	batchSize: number,
	part: Part,
	manufacturingMethod: string,
	configuration: any,
	outsourcedCNCManufacturing: boolean,
	partPrintIssues: PartPrintIssue[]
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: COST_COMPARISON_EDIT_STARTED,
				payload: {
					id
				}
			})

			const response = await updateOutSourcedCNCManufacturing(
				configuration.id,
				!outsourcedCNCManufacturing
			)

			await solutionAnalysisService.updateSolution(
				configuration,
				configuration.solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				null,
				false,
				true
			)
			dispatch({
				type: OUT_SOURCED_CNC_MANUFACTURING_TOGGLE_CHANGE,
				payload: { id }
			})
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})

			// The code beneath is a plaster for the 'react-apexChart' rerender xAxis
			// bug. issue: https://github.com/apexcharts/react-apexcharts/issues/94
			dispatch({
				type: COST_COMPARISON_EDIT_CLICKED,
				payload: {
					id
				}
			})
			dispatch({
				type: COST_COMPARISON_FORM_CANCELLED,
				payload: {
					id
				}
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			console.error(error)
		}
	}
}
export const onCostChainBenefitsUpdated = (
	id: number,
	batchSize: number,
	postProcessesOptional: any,
	manufacturingMethod: string,
	tempChainBenefits: IChainBenefits,
	chainBenefitName: ChainBenefitsNames,
	configurations: Array<any>,
	expectedYearsOfDemand: number,
	chosenOrientation: any
) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: CHAIN_BENEFITS_UPDATE_STARTED,
				payload: {
					id
				}
			})
			solutionAnalysisService.turnOnChainBenefit(
				chainBenefitName,
				tempChainBenefits
			)
			const response = await updateSolutionQuantity(
				id,
				batchSize,
				postProcessesOptional,
				tempChainBenefits,
				expectedYearsOfDemand,
				chosenOrientation
			)

			const { configuration, partPrintIssues } = response?.data
			const { part } = configuration
			const keepForm = true
			await solutionAnalysisService.updateSolution(
				configuration,
				configuration.solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				configuration.alternativeSolutions,
				keepForm
			)

			const updateConfigurations = configurations.filter(
				(configuration: IConfiguration) => !!configuration.solution
			)

			await updateAllOthersConfigurations(
				updateConfigurations,
				id,
				part,
				dispatch,
				partPrintIssues
			)
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			dispatch({
				type: MAIN_PART_UPDATE_CHAIN_BENEFITS,
				payload: { id, part, partPrintIssues }
			})
			dispatch({
				type: CHAIN_BENEFITS_UPDATE_FINISHED,
				payload: {
					id
				}
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			console.error(error)
		}
	}
}

export const onCostQuantityUpdated = (
	id: number,
	batchSize: number,
	postProcessesOptional: any,
	part: Part,
	manufacturingMethod: string,
	tempChainBenefits: IChainBenefits,
	expectedYearsOfDemand: number,
	chosenOrientation: any,
	isLeadTime?: boolean
): any => {
	return async (dispatch: any) => {
		if (batchSize < 1) {
			return
		}
		try {
			dispatch({
				type: COST_COMPARISON_EDIT_STARTED,
				payload: {
					id
				}
			})
			dispatch({
				type: UPDATE_QUANTITY_TOGGLED
			})
			const response = await updateSolutionQuantity(
				id,
				batchSize,
				postProcessesOptional,
				tempChainBenefits,
				expectedYearsOfDemand,
				chosenOrientation,
				true
			)

			const { configuration, partPrintIssues } = response?.data
			const updatedAlternativeSolutions = configuration.alternativeSolutions
			const postProcessesIdsWithAbnormalValues =
				getPostProcessIdsWithAbnormalValues(configuration.solution)

			if (
				configuration?.solution?.isAbnormalPrice ||
				configuration?.traditionalCostIsAbnormal ||
				postProcessesIdsWithAbnormalValues?.length
			) {
				dispatch(
					showCustomFunctionWarningPopup(postProcessesIdsWithAbnormalValues)
				)
			}
			await solutionAnalysisService.updateSolution(
				configuration,
				configuration.solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				updatedAlternativeSolutions,
				false,
				true,
				true,
				isLeadTime
			)
			dispatch({
				type: UPDATE_QUANTITY_TOGGLED
			})

			dispatch({
				type: MAIN_PART_PART_PRINT_ISSUES_UPDATED,
				payload: {
					partPrintIssues
				}
			})

			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})

			// The code beneath is a plaster for the 'react-apexChart' rerender xAxis
			// bug. issue: https://github.com/apexcharts/react-apexcharts/issues/94
			dispatch({
				type: COST_COMPARISON_EDIT_CLICKED,
				payload: {
					id
				}
			})
			dispatch({
				type: COST_COMPARISON_FORM_CANCELLED,
				payload: {
					id
				}
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			console.error(error)
		}
	}
}

export const solutionMaterialPrioritiesChanged = (
	id: number,
	chosenMaterial: any
): any => {
	return (dispatch: Dispatch<AnyAction>) => {
		const {
			user: { priorities: userPriorities }
		} = store.getState()

		const solutionPriorities: Map<string, number> =
			solutionAnalysisService.setupSolutionPriorities(
				chosenMaterial.defaultPriorities,
				userPriorities
			)
		dispatch({
			type: SOLUTION_CONFIGURE_PRIORITIZE_CHANGED,
			payload: { id, solutionPriorities }
		})
	}
}

export const onShowAllComparsClick = (id: number): ActionWithPayload<any> => {
	return {
		type: MATERIAL_COMPARISON_SHOW_ALL_CLICKED,
		payload: { id }
	}
}

export const onFailedPartClicked = (part: Part, id: number): any => {
	return (dispatch: any) => {
		let showingSimpleAlertTitle = ''
		let showingSimpleAlertText = ''
		if (part.result === partResults.failed) {
			showingSimpleAlertTitle = FAILED_PROCESSING_PART_ALERT_TITLE
			showingSimpleAlertText = part.errorMessage || ''
		}
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: showingSimpleAlertText,
				headerTitle: showingSimpleAlertTitle,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
				},
				showCancel: false
			}
		})
	}
}

export const onWallThicknessClick = (id: number, dispatch?: any): any => {
	if (dispatch) {
		dispatch({
			type: CONFIGURATION_WALL_THICKNESS_CLICKED,
			payload: { id }
		})
	}
	return (dispatch: any) => {
		dispatch({
			type: CONFIGURATION_WALL_THICKNESS_CLICKED,
			payload: { id }
		})
	}
}
export const onMeshHealingConfirmed = async (
	partId: number,
	dispatch: any,
	configuration: any,
	solution: any,
	quantity: number,
	isOnPrem?: boolean,
	disableMeshHealing?: boolean
) => {
	dispatch({ type: ALERT_CALCULATION_STARTED })
	try {
		const response = await createMeshHealing(partId)
		const {
			healedStlURL,
			configurations,
			part,
			meshHealingOperated,
			partPrintIssues
		} = response?.data

		if (!meshHealingOperated) {
			//update part
			dispatch({
				type: SOLUTION_CONFIGURATION_MESH_HEALING_PART_UPDATED,
				payload: {
					part,
					configurations,
					partPrintIssues
				}
			})
			dispatch({
				type: CONFIGURATION_CALCULATED
			})

			await solutionAnalysisService.updateConfigurations(
				configurations,
				dispatch,
				partPrintIssues
			)
		}

		if (
			Feature.isFeatureOn(FeatureComponentId.GET_A_Q) &&
			!isOnPrem &&
			!disableMeshHealing
		) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_MESH_HEALING_OPERATED_EXPLENATION,
					headerTitle: CONFIGURATION_MESH_HEALING_OPERATED_TITLE,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
						meshHealingSendToPrint(configuration.id)
						history.push(
							`${SEND_TO_SERVICE_BUREAU_ROUTE}/${solution.projectId}/${solution.partId}/${quantity}/${solution.printer.name}/${solution.printerMaterial.name}/${configuration.id}`
						)
					},
					loadingCalculation: false,
					cancelText: DONE,
					confirmText: SEND_TO_SERVICE_BUREAU_TITLE,
					extraButton: {
						text: DOWNLOAD,
						onConfirm: () => {
							dispatch({ type: ALERT_POPUP_CANCELED })
							partDownload(partId)
							window.open(healedStlURL)
						}
					}
				}
			})
		} else {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_MESH_HEALING_OPERATED_EXPLENATION,
					headerTitle: CONFIGURATION_MESH_HEALING_OPERATED_TITLE,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
						partDownload(partId)
						window.open(healedStlURL)
					},
					loadingCalculation: false,
					cancelText: DONE,
					confirmText: DOWNLOAD
				}
			})
		}
	} catch (error: any) {
		console.error(`error in onMeshHealingConfirmed. ${error.message}`)
		dispatch({ type: ALERT_POPUP_CANCELED })
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: CONFIGURATION_CALCULATION_ERROR
			}
		})
	}
}

export const onMeshHealingClick = (
	partId: number,
	configuration: any,
	solution: any,
	quantity: number,
	meshHealingOperated: boolean,
	isOnPrem?: boolean,
	disableMeshHealing?: boolean
): any => {
	return (dispatch: any) => {
		if (meshHealingOperated) {
			onMeshHealingConfirmed(
				partId,
				dispatch,
				configuration,
				solution,
				quantity,
				isOnPrem,
				disableMeshHealing
			)
		} else {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_MESH_HEALING_EXPLENATION,
					headerTitle: MESH_HEALING,
					alertType: AlertType.WARNING,
					alertClass: 'responsive-modal',
					onConfirm: () => {
						onMeshHealingConfirmed(
							partId,
							dispatch,
							configuration,
							solution,
							quantity,
							isOnPrem,
							disableMeshHealing
						)
					},
					confirmText: CONFIGURATION_MESH_HEALING_CONFIRM
				}
			})
		}
	}
}

export const onTolerancesConfirm = (
	partId: number,
	configurationId: number,
	toleranceValue: string,
	removeInnerPartTolerance: boolean,
	successMessage?: string
): any => {
	return async (dispatch: any) => {
		dispatch({
			type: CONFIGURATION_TOLERANCES_LOADING_TOGGLED,
			payload: { id: configurationId }
		})
		try {
			const response = await updatePartTolerance(
				partId,
				toleranceValue,
				removeInnerPartTolerance
			)
			const {
				data: { configurations, part, cluster, solutions, partPrintIssues }
			} = response as any

			const configuration = configurations.find(
				(c: any) => c.id === configurationId
			)

			const res = await solutionAnalysisService.startConditionPoller(
				configuration,
				configurationId,
				configurations,
				dispatch,
				true
			)

			if (!res) {
				await solutionAnalysisService.updateConfigurations(
					configurations,
					dispatch,
					partPrintIssues,
					solutions
				)
				dispatch({ type: ALERT_POPUP_CANCELED })
			}
			dispatch({ type: ALERT_CALCULATION_FINISHED })
			dispatch({
				type: PART_CONFIGURATIONS_SOLUTIONS_UPDATED,
				payload: {
					part,
					cluster,
					configurations,
					solutions,
					partPrintIssues
				}
			})

			dispatch({
				type: CONFIGURATION_TOLERANCES_LOADING_TOGGLED,
				payload: { id: configurationId }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: successMessage || TOLERANCES_UPDATE_SUCCESS_MSG
				}
			})
			dispatch({ type: CONFIGURATION_CALCULATED })
			dispatch({
				type: TOLERANCES_CANCELLED,
				payload: { id: configurationId }
			})
		} catch (error: any) {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: CONFIGURATION_TOLERANCES_LOADING_TOGGLED,
				payload: { id: configurationId }
			})
		}
	}
}
export const onWallThicknessConfirm = (
	id: number,
	customWallThickness: number,
	solution: any,
	part: Part,
	configuration: any,
	manufacturingMethod: string,
	updateOrientation?: boolean
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED,
				payload: { id }
			})
			const configurations =
				store.getState().MainPartAnalysisReducer.configurations
			let response = await solutionAnalysisService.updateWallThickness(
				customWallThickness,
				configuration,
				configurations,
				updateOrientation
			)

			const {
				wallThicknessScore,
				wallThicknessTestInMM,
				wallThicknessFailReason,
				result,
				wallThickessModelURL,
				heatMapImageURL,
				partPrintIssues,
				pollerPerformed
			} = response

			configuration.result = result
			configuration.heatMapImageURL = heatMapImageURL || ''
			configuration.wallThicknessFailReason = wallThicknessFailReason
			configuration.wallThicknessScore = wallThicknessScore
			configuration.wallThicknessTestInMM = wallThicknessTestInMM
			configuration.wallThickessModelURL = wallThickessModelURL

			await solutionAnalysisService.updateSolution(
				configuration,
				solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				configuration.alternativeSolutions,
				false,
				true
			)
			updateConfiguration(id, configuration)
			const iframe: any = document.getElementById('iframe')
			if (iframe) {
				iframe.contentWindow.postMessage(
					['newWallThickness', wallThickessModelURL],
					'*'
				)
			}
			dispatch({
				type: NOTIFICATION_CLEARED
			})
			dispatch({
				type: MAIN_PART_PART_PRINT_ISSUES_UPDATED,
				payload: {
					partPrintIssues
				}
			})
			dispatch({
				type: CONFIGURATION_WALL_THICKNESS_LOADING_UPDATED,
				payload: { id }
			})
			pollerPerformed &&
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: WALL_THICKNESS_NOTIFICATION_SUCCEDED
					}
				})

			solutionAnalysisService.dispatchUpdatingPartUI(configurations, id)
			dispatch({
				type: CONFIGURATION_CALCULATED
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.message || WALL_THICKNESS_NOTIFICATION_FAILED
				}
			})
			dispatch({
				type: CONFIGURATION_WALL_THICKNESS_LOADING_TOGGLED,
				payload: { id }
			})
		}
	}
}

export const onWallThicknessCancel = (id: number): ActionWithPayload<any> => {
	return {
		type: CONFIGURATION_WALL_THICKNESS_CANCELLED,
		payload: { id }
	}
}
export const onTolerancesClick = (id: number, dispatch?: any): any => {
	if (dispatch) {
		dispatch({
			type: TOLERANCES_CLICKED,
			payload: { id }
		})
	}
	return (dispatch: any) => {
		dispatch({
			type: TOLERANCES_CLICKED,
			payload: { id }
		})
	}
}

export const onTolerancesRemove = (
	configurationId: number,
	part: Part
): any => {
	return async (dispatch: any) => {
		try {
			const popupContent = !isEmpty(part.tolerances?.dimensionalMarkups)
				? `${getString('REMOVE_TOLERANCE_POPUP_CONTENT')}\n${getString(
						'REMOVE_TOLERANCE_POPUP_CONTENT_EXTRA'
				  )}`
				: getString('REMOVE_TOLERANCE_POPUP_CONTENT')
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: popupContent,
					headerTitle: getString('REMOVE_TOLERANCE_POPUP_TITLE'),
					alertType: AlertType.WARNING,
					onConfirm: async () => {
						dispatch({ type: ALERT_CALCULATION_STARTED })
						await dispatch(
							onTolerancesConfirm(
								part.id,
								configurationId,
								ToleranceClass.TOLERANCE_CLASS_IRRELEVANT,
								true,
								getString('TOLERANCES_REMOVE_SUCCESS_MSG')
							)
						)
					},
					onCancel: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
						dispatch({
							type: TOLERANCES_CLICKED,
							payload: { id: configurationId }
						})
					},
					showCancel: true,
					confirmText: getString('YES'),
					cancelText: getString('NO')
				}
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			console.error(error)
		}
	}
}

export const onTolerancesCancel = (id: number): ActionWithPayload<any> => {
	return {
		type: TOLERANCES_CANCELLED,
		payload: { id }
	}
}
export const show3dModalIframe = (
	id: number,
	isCluster: boolean = false
): ActionWithPayload<any> => {
	return {
		type: CONFIGURATION_ANALYSIS_3D_MODAL_OPENED,
		payload: { id, isCluster }
	}
}

export const hide3dModalIframe = (id: number): ActionWithPayload<any> => {
	return {
		type: CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED,
		payload: { id }
	}
}
export const onManufacturingMethodChange = (
	id: number,
	manufacturingMethod: string,
	configuration: any,
	partPrintIssues: PartPrintIssue[],
	isLeadTime?: boolean
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: COST_COMPARISON_EDIT_STARTED,
				payload: {
					id
				}
			})
			let configurationMethod: ImanufacturingTypes

			switch (manufacturingMethod) {
				case manufacturingMethodTypes.mold:
					configurationMethod = ImanufacturingTypes.mold
					break
				case manufacturingMethodTypes.cnc:
					configurationMethod = ImanufacturingTypes.cnc
					break
				case manufacturingMethodTypes.cast:
					configurationMethod = ImanufacturingTypes.cast
					break
				case manufacturingMethodTypes.investmentCast:
					configurationMethod = ImanufacturingTypes.investmentCast
					break
				case manufacturingMethodTypes.standardCost:
					configurationMethod = ImanufacturingTypes.standardCost
					break
				default:
					configurationMethod = ImanufacturingTypes.mold
					break
			}

			await updateConfigurationTraditionalMethod(id, configurationMethod)

			await solutionAnalysisService.updateSolution(
				configuration,
				configuration.solution,
				dispatch,
				manufacturingMethod,
				partPrintIssues,
				null,
				false,
				false,
				false,
				isLeadTime
			)
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			// The code beneath is a plaster for the 'react-apexChart' rerender xAxis
			// bug. issue: https://github.com/apexcharts/react-apexcharts/issues/94
			dispatch({
				type: COST_COMPARISON_EDIT_CLICKED,
				payload: {
					id
				}
			})
			dispatch({
				type: COST_COMPARISON_FORM_CANCELLED,
				payload: {
					id
				}
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: COST_COMPARISON_EDIT_FINISHED,
				payload: {
					id
				}
			})
			console.error(error)
		}
	}
}

export const onCompanyChangeSimpleConfiguration = (
	id: number,
	value: string,
	change: Function
): any => {
	const printersList =
		simpleConfigurationSelectorService.getPrintersNamesList(value)

	return (dispatch: any) => {
		dispatch({
			type: SIMPLE_CONFIGURATION_SELECTOR_COMPANY_CHANGED,
			payload: { id, value, printersList }
		})
		if (printersList.length === 1) {
			const printer = printersList[0]
			const materialsList =
				simpleConfigurationSelectorService.getPrinterMaterialsList(printer)
			dispatch({
				type: SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED,
				payload: { id, value: printer, materialsList }
			})

			if (materialsList.length === 1) {
				const material = materialsList[0]
				dispatch({
					type: SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED,
					payload: { id, value: material }
				})
				change(
					`solutionConfigureFilter_${id}`,
					'printerMaterialID',
					material.id
				)
			}
		}
	}
}

export const onPrinterChangeSimpleConfiguration = (
	id: number,
	value: string,
	change: Function
): any => {
	const materialsList =
		simpleConfigurationSelectorService.getPrinterMaterialsList(value)

	return (dispatch: any) => {
		dispatch({
			type: SIMPLE_CONFIGURATION_SELECTOR_PRINTER_CHANGED,
			payload: { id, value, materialsList }
		})
		if (materialsList.length === 1) {
			const material = materialsList[0]
			dispatch({
				type: SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED,
				payload: { id, value: material }
			})
			change(`solutionConfigureFilter_${id}`, 'printerMaterialID', material.id)
		}
	}
}

export const onMaterialChangeSimpleConfiguration = (
	id: number,
	value: any,
	change: Function
): ActionWithPayload<any> => {
	change(`solutionConfigureFilter_${id}`, 'printerMaterialID', value.id)
	return {
		type: SIMPLE_CONFIGURATION_SELECTOR_MATERIAL_CHANGED,
		payload: { id, value }
	}
}

export const startFEAImmediately = (
	configurationId: number,
	partId: number,
	localStorageKey: string,
	solutionFeaId?: string
) => {
	return (dispatch: any) => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		removeItemFromLocalStorage(localStorageKey)
		dispatch(onNewFeaClick(configurationId, partId, solutionFeaId))
	}
}

export const onNewFeaClick = (id: number, partId: number, feaId?: string) => {
	return async (dispatch: any) => {
		let type = FEA_NEW_ANALYSIS_TOGGLED
		if (feaId) {
			type = FEA_OLD_ANALYSIS_TOGGLED
		}
		try {
			if (!Feature.isFeatureOn(FeatureComponentId.ENTERPRISE_FEATURES)) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString(
							'MECHANICAL_ANALYSIS_ENTERPRISE_FEATURES_EXPLANATION'
						),
						headerTitle: getString('CASTOR_ENTERPRISE_FEATURES'),
						onConfirm: () => {
							sendReNewSubscriptionRequest()
							dispatch({
								type: ALERT_POPPED,
								payload: {
									text: getString('UPGRADE_REQUEST_CONFIRMED_TEXT'),
									headerTitle: getString('UPGRADE_REQUEST_HEADER_TEXT'),
									alertType: AlertType.SUCCESS,
									onConfirm: () => {
										dispatch({ type: ALERT_POPUP_CANCELED })
									},
									showCancel: false,
									confirmText: OK
								}
							})
						},
						confirmText: getString('UPGRADE_REQUEST_HEADER_TEXT')
					}
				})
				return
			}

			dispatch({ type: FEA_NEW_ANALYSIS_CLICKED, payload: { id } })
			dispatch({ type, payload: { id } })
			const {
				data: { requestUrl, request }
			} = (await createFeaRequest(partId, id, false, feaId)) as any
			const response = await getCaeplexRedirectURL(requestUrl, request)
			const { redirectURL } = JSON.parse(response)

			const localStorageTabKey = analysisTabLSKey({
				partId,
				configurationId: id
			})

			setStringItemToLocalStorage(localStorageTabKey, tabOrder.feaAnalysis)

			dispatch({ type, payload: { id } })
			window.location.replace(redirectURL)
		} catch (error: any) {
			console.error(error)
			dispatch({ type, payload: { id } })
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onFeaAlertCancel = (id: number, feaId: string) => {
	return async (dispatch: any) => {
		try {
			dispatch({ type: FEA_ANALYSIS_ALERT_CANCELED, payload: { id } })
			saveFeaAnalysisData(feaId, [], '', true)
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onFeaAlertConfirm = (
	id: number,
	feaId: string,
	userInputs: number[],
	feaAnalysisResultsId: string,
	weightReductionType: WeightReductionType
) => {
	return async (dispatch: any) => {
		try {
			dispatch({ type: FEA_ANALYSIS_ALERT_LOADER_TOGGLED, payload: { id } })
			const {
				data: { solutionFea, feaValues }
			} = (await saveFeaAnalysisData(
				feaId,
				userInputs,
				feaAnalysisResultsId
			)) as any
			dispatch({
				type: FEA_ANALYSIS_DATA_FETCHED,
				payload: { id, feaValues, solutionFea }
			})

			if (
				weightReductionType ===
				WeightReductionType.PREPARING_FOR_WEIGHT_REDUCTION
			) {
				if (solutionFea.result === FeaResult.failed) {
					await updatePartWeightReductionType(
						solutionFea.part.id,
						WeightReductionType.SUITABLE_FOR_WR
					)

					dispatch({
						type: SOLUTION_CONFIGURE_STATE_UPDATED,
						payload: {
							id: solutionFea.configuration.id,
							showWeightReductionButton: true
						}
					})
					dispatch({
						type: ALERT_POPPED,
						payload: {
							headerTitle: getString('WEIGHT_REDUCTION_FEA_REQ_FAILED_HEADER'),
							confirmText: getString('OK'),
							text: getString('WEIGHT_REDUCTION_FEA_FAILED'),
							showCancel: false,
							onConfirm: () => {
								dispatch({ type: ALERT_POPUP_CANCELED })
							}
						}
					})
				} else {
					await handleWeightReductionState(id, dispatch)
				}
			}
		} catch (error: any) {
			dispatch({ type: FEA_ANALYSIS_ALERT_LOADER_TOGGLED, payload: { id } })
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onPartImageTabClicked = (id: number, chosenTabIndex: number) => {
	return {
		type: PART_IMAGE_TABS_CLICKED,
		payload: { id, chosenTabIndex }
	}
}
export const orientationInfoButtonClicked = (id: number) => {
	return {
		type: TRAY_ORIENTATION_INFO_BUTTON_CLICKED,
		payload: { id }
	}
}

export const onViewerModelError = (id: number, error: any): any => {
	return (dispatch: any) => {
		console.error(error)
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: `error rendering model`
			}
		})
		dispatch({
			type: CONFIGURATION_ANALYSIS_3D_MODAL_CLOSED,
			payload: { id }
		})
	}
}
export const onOrientationConfirmNoChange = (id: number): any => {
	return {
		type: ORIENTATION_CONFIRM_NO_CHANGE,
		payload: {
			id
		}
	}
}
export const onWeightReductionButtonClick = (
	id: number,
	solution: any
): any => {
	return (dispatch: any) => {
		if (!solution || !solution.printer) {
			dispatch({
				type: OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
				payload: { id }
			})
		}
		if (!solution?.printerTechnology?.canPerformWeightReduction) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_TEXT,
					headerTitle: WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_HEADER,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
						dispatch({
							type: OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
							payload: { id }
						})
					},
					showCancel: false,
					confirmText: OK
				}
			})
		} else {
			dispatch({
				type: OPEN_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
				payload: { id }
			})
		}
	}
}
export const onCancelWeightReductionButtonClick = (id: number): any => {
	return {
		type: CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP_SOLUTION_ANALYSIS,
		payload: { id }
	}
}

export const onGeometryAnalysisReviewAndFixesClick = (
	id: number
): ActionWithPayload<any> => {
	return {
		type: GEOMETRY_ANALYSIS_ALERT_OPENED,
		payload: {
			id
		}
	}
}

export const onGeometryAnalysisReviewAndFixesAlertCancel = (
	id: number
): ActionWithPayload<any> => {
	return {
		type: GEOMETRY_ANALYSIS_ALERT_CANCELLED,
		payload: {
			id
		}
	}
}

export const onPartPrintIssueChanged = (
	partIssueId: number,
	issueActivity: boolean,
	partId: number,
	configurationId: number
) => {
	return async (dispatch: any) => {
		try {
			const state = store.getState()

			dispatch({
				type: GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED,
				payload: {
					id: configurationId,
					partIssueId
				}
			})
			const changePartPrintIssueActivityResponse =
				await changePartPrintIssueActivity(partIssueId, issueActivity, partId)
			const {
				configurations,
				part,
				cluster,
				solutions,
				partPrintIssues,
				alternativeSolutions
			} = changePartPrintIssueActivityResponse?.data as {
				configurations: any[]
				part: Part | null
				cluster: IClusterPart | null
				solutions: any[]
				partPrintIssues: PartPrintIssue[]
				alternativeSolutions: ISolution[]
			}

			const alternativeSolutionsConfig = configurations.find(
				config => config.alternativeSolutions?.length > 0
			)
			const {
				user: { defaultSettings },
				MainPartAnalysisReducer: { allConfigurationsOrganizationSettings }
			} = store.getState()

			const drawingCostPercentage =
				allConfigurationsOrganizationSettings[
					alternativeSolutionsConfig?.organizationId
				]?.customizationSettings?.drawingCostPercentage ||
				defaultSettings?.drawingCostPercentage

			dispatch({
				type: PART_CONFIGURATIONS_SOLUTIONS_UPDATED,
				payload: {
					part,
					cluster,
					configurations,
					solutions,
					partPrintIssues
				}
			})
			await solutionAnalysisService.updateConfigurations(
				configurations,
				dispatch,
				partPrintIssues,
				solutions,
				true
			)

			dispatch({
				type: GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED,
				payload: {
					id: configurationId,
					partIssueId
				}
			})

			dispatch({
				type: CONFIGURATION_CALCULATED
			})

			dispatch({
				type: GET_ALTERNATIVE_SOLUTION_CALCULATED,
				payload: {
					solutions: alternativeSolutions || [],
					drawingCostPercentage
				}
			})
		} catch (error: any) {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
			dispatch({
				type: GEOMETRY_ANALYSIS_PRINT_ISSUE_LOAD_TOGGLED,
				payload: {
					id: configurationId,
					partIssueId
				}
			})
		}
	}
}

export const onChangeLockLeadTimeGraph = (id: number): any => {
	return async (dispatch: any) => {
		try {
			dispatch({ type: LEAD_TIME_GRAPH_UNLOCKED_STARTED, payload: { id } })
			let leadTimeData: LeadData = new LeadData()

			const result = await updateLockLeadTimeGraph(id)

			if (result?.data?.configuration) {
				const { isAmOriginalMaterial } = useSelector(
					(state: RootStateOrAny) => {
						return state?.SolutionAnalysisReducer.states[
							result.data.configuration.id
						]
					}
				)
				const configuration = result.data.configuration

				const initialMaterialType = configuration?.material?.type
				const configurationMethod: ImanufacturingTypes =
					configuration?.manufactureMethod ||
					(initialMaterialType === materialTypes?.plastic
						? ImanufacturingTypes.mold
						: ImanufacturingTypes.cnc)

				const manufacturingMethod: string =
					manufacturingMethodTypes[configurationMethod]

				if (configuration.leadTimeResults) {
					leadTimeData = leadTimeService.getLeadData(
						configuration.leadTimeResults,
						manufacturingMethod,
						configuration.unlockLeadTimeGraph,
						configuration.quantity,
						!!configuration?.part?.standardCost && !configuration.standardCost,
						configuration,
						isAmOriginalMaterial
					)
				}

				dispatch({
					type: LEAD_TIME_GRAPH_UNLOCKED_SUCCESS,
					payload: {
						id: configuration.id,
						unlockLeadTimeGraph: configuration.unlockLeadTimeGraph,
						chartLeadData: leadTimeData.chartData
					}
				})
			}
		} catch (e) {
			dispatch({ type: LEAD_TIME_GRAPH_UNLOCKED_ERROR, payload: { id } })
		}
	}
}

export const onCastToolingToggleChange = (id: number, isTooling: boolean) => {
	return (dispatch: any) => {
		dispatch({
			type: TOOLING_TOGGLE_CHANGE_SUCCESS,
			payload: { id: id, isTooling: isTooling }
		})
	}
}

export const resetTooling = (
	id: number,
	isTooling: boolean
): ActionWithPayload<any> => {
	return {
		type: TOOLING_TOGGLE_RESET,
		payload: { id, isTooling }
	}
}

export const showCustomFunctionWarningPopup = (
	postProcessesIdsWithAbnormalPrice: string[] = []
): any => {
	return (dispatch: any) => {
		let postProcessesNames: string[] = []
		const isCostFunctionOn = Feature.isFeatureOn(
			FeatureComponentId.COST_FUNCTION_EDITOR
		)
		const {
			user: { allOptionalPostProcessesData, userDetails = {} }
		} = store.getState()
		let text: string = userDetails.organization_owner
			? getString('CUSTOM_FUNCTION_WAS_USED_TEXT')
			: getString('CUSTOM_FUNCTION_WAS_USED_TEXT_REGULAR_USER')

		let link = USER_HOME_ROUTE + CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE
		let confirmText = getString('CUSTOM_FUNCTION_WAS_USED_OK')
		if (postProcessesIdsWithAbnormalPrice.length) {
			const postProcessesWithAbnormalPrice =
				allOptionalPostProcessesData.filter((postProcess: IPostProcess) =>
					postProcessesIdsWithAbnormalPrice.find(id => id == postProcess.id)
				)
			postProcessesWithAbnormalPrice.forEach((pp: IPostProcess) =>
				postProcessesNames.push(getString(pp.labelName) || pp.name)
			)
			text = getString('PP_CUSTOM_FUNCTION_WAS_USED_TEXT').format(
				postProcessesNames.join(', ')
			)
			link =
				USER_HOME_ROUTE +
				CUSTOMIZE_COST_AND_LEAD_ROUTE +
				POST_PROCESSES_BLOCK_ID_HASH

			confirmText = getString('CUSTOMIZE_MACHINING_TITLE')
		}

		dispatch({
			type: ALERT_POPPED,
			payload: {
				text,
				headerTitle: getString('CUSTOM_FUNCTION_WAS_USED_TITLE'),
				alertType: AlertType.WARNING,
				onConfirm: () => {
					window.location.href = userDetails.organization_owner
						? link
						: USER_HOME_ROUTE + UPLOAD_ROUTE
				},
				onCancel: () => {
					window.location.href = USER_HOME_ROUTE + UPLOAD_ROUTE
				},
				onButtonHoverText:
					!isCostFunctionOn &&
					getString('CUSTOM_FUNCTION_WAS_USED_CONTACT_ADMINISTRATOR'),
				disabled: !isCostFunctionOn,
				confirmText: userDetails.organization_owner
					? confirmText
					: getString('OK'),
				showCancel: false,
				closeOnClickOutside: false
			}
		})
	}
}

export const toggleAssemblingCostModal = (id: number, open: boolean) => {
	return {
		type: TOGGLE_ASSEMBLING_COST_MODAL,
		payload: { id, open }
	}
}

export const setAssemblingCostParams = (
	id: number,
	type: string,
	value: string,
	setLoading: (loading: boolean) => void
) => {
	return async (dispatch: any) => {
		try {
			setLoading(true)
			let assemblingParams = null
			if (type !== assemblingCalculationType.DEFAULT) {
				assemblingParams = {
					[type]: Number(value)
				}
			}
			const response = await updateClusterAssemblingParams(id, assemblingParams)
			const configuration = response.data.configuration

			dispatch({
				type: ASSEMBLING_PARAMS_UPDATED,
				payload: { id, configuration }
			})
			setLoading(false)
		} catch (error: any) {
			console.log(error)
			setLoading(false)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const changeOrganizationToConfigure = (
	configurationId: number,
	organizationId: number,
	reset = false,
	configurationResultType?: ConfigurationResultTypes
) => {
	return async (dispatch: any) => {
		try {
			const { allConfigurationsOrganizationSettings } =
				store.getState().MainPartAnalysisReducer

			if (!reset) {
				dispatch({
					type: START_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS,
					payload: { id: configurationId }
				})
			}
			if (!allConfigurationsOrganizationSettings[organizationId]) {
				const response = await getConfigurationOrganizationSettings(
					organizationId
				)
				dispatch({
					type: EXTEND_CONFIGURATION_ORGANIZATION_SETTINGS,
					payload: {
						organizationId,
						configurationOrganizationSettings:
							response?.data?.configurationOrganizationSettings || {}
					}
				})
				allConfigurationsOrganizationSettings[organizationId] =
					response?.data?.configurationOrganizationSettings
			}
			if (
				configurationResultType === ConfigurationResultTypes.InHouse &&
				allConfigurationsOrganizationSettings[organizationId].printers
					.length === 0
			) {
				dispatch({
					type: FINISHED_FETCHING_CONFIGURATION_ORGANIZATION_SETTINGS,
					payload: {
						id: configurationId
					}
				})
				dispatch({
					type: ALERT_POPPED,
					payload: {
						headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
						text: getString(
							'SOLUTION_CONFIGURATION_SITE_NO_IN_HOUSE_PRINTERS_WARNING'
						),
						alertType: AlertType.WARNING,
						onConfirm: () => {
							dispatch({ type: ALERT_POPUP_CANCELED })
						},
						confirmText: getString('OK'),
						showCancel: false
					}
				})
			} else {
				dispatch({
					type: CHANGE_ORGANIZATION_TO_UPDATE_CONFIGURATION,
					payload: {
						id: configurationId,
						calculateWithOrganizationId: organizationId
					}
				})
				dispatch({ type: SOLUTION_DO_SETUP, payload: { id: configurationId } })
			}
		} catch (error: any) {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}
