import React, { FC, memo, useState } from 'react'

import classnames from 'classnames'
import { isEmpty, map } from 'lodash'

import CreateChartLabel from './CreateChartLabel'
import PieFooter from './PieFooter'
import ResultsLabel from './ResultsLabel'
import FlexBox from 'Scenes/Components/FlexBox'
import { pieNotCostEffectiveColor, piePassedColor } from 'Services/colors'
import {
	PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES,
	partResults
} from 'Services/Constants'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	styles: {
		card: Record<string, unknown>
		horizontalFlex: Record<string, unknown>
		footerFlex: Record<string, unknown>
		footer: Record<string, unknown>
		pieSuper: Record<string, unknown>
		text: Record<string, unknown>
	}
	numberOfPrintableParts: any
	partsCountWithoutFailed: any
	allPartsInapplicable: any
	chartData: any
	benefitsState: any
	isAssembly: boolean
	isMetaDataProject: boolean
	is2dProject: boolean
}

const moсkBomAnalysis = (chartData: any) =>
	map(chartData, data => {
		switch (data.name) {
			case CADAnalysisResult.borderline:
				return { ...data, name: partResults.optimized, color: piePassedColor }
			case CADAnalysisResult.printable:
				return {
					...data,
					name: partResults.latheCNC,
					color: pieNotCostEffectiveColor
				}
			case CADAnalysisResult.notPrintable:
				return { ...data, name: partResults.castingCNC }
			case partResults.sheetMetal:
				return { ...data, name: partResults.sheetMetal }
		}
	}).filter(elem => !isEmpty(elem))

const OptimizationAnalysisChart: FC<IProps> = ({
	allPartsInapplicable,
	chartData,
	partsCountWithoutFailed,
	numberOfPrintableParts,
	isAssembly,
	styles,
	is2dProject,
	isMetaDataProject
}) => {
	const [partResultHovered, setPartResultHovered] = useState('')
	const changedData = moсkBomAnalysis(chartData)
	return (
		<FlexBox
			flexDirection="column"
			className={classnames('project-results--block', {
				small: allPartsInapplicable || !changedData,
				'without-assembly-block': !isAssembly
			})}
			style={styles.footerFlex}
		>
			<ResultsLabel
				title={getString('BOM_OPTIMIZATION_ANALYSIS')}
				partsCount={partsCountWithoutFailed}
				printablePartsCount={numberOfPrintableParts}
				isAssembly={isAssembly}
			/>
			<CreateChartLabel
				chartData={changedData}
				isAssembly={isAssembly}
				partResultHovered={(data: any) => setPartResultHovered(data)}
			/>
			<PieFooter
				partStatuses={PART_RESULTS_OPTIMIZED_WITH_TITLE_INDEXES}
				chartIsEmpty={!changedData}
				is2dProject={is2dProject || isMetaDataProject}
				isAssembly={isAssembly}
				partResultHovered={partResultHovered}
			/>
		</FlexBox>
	)
}

export default memo(OptimizationAnalysisChart)
