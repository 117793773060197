import React, { FC, memo, useState } from 'react'

import classnames from 'classnames'
import { VictoryPie } from 'victory'

import CreateChartLabel from './CreateChartLabel'
import PieFooter from './PieFooter'
import ResultsLabel from './ResultsLabel'
import FlexBox from 'Scenes/Components/FlexBox'
import { PART_RESULTS_LED_WITH_TITLE_INDEXES } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	styles: {
		card: Record<string, unknown>
		horizontalFlex: Record<string, unknown>
		footerFlex: Record<string, unknown>
		footer: Record<string, unknown>
		pieSuper: Record<string, unknown>
		text: Record<string, unknown>
	}
	numberOfPrintableParts: any
	partsCountWithoutFailed: any
	allPartsInapplicable: any
	chartData: any
	benefitsState: any
	isAssembly: boolean
	isMetaDataProject: boolean
	is2dProject: boolean
}

const PrintabilityAnalysisChart: FC<IProps> = ({
	allPartsInapplicable,
	chartData,
	partsCountWithoutFailed,
	numberOfPrintableParts,
	isAssembly,
	styles,
	is2dProject,
	isMetaDataProject
}) => {
	const [partResultHovered, setPartResultHovered] = useState('')

	return (
		<FlexBox
			flexDirection="column"
			className={classnames('project-results--block', {
				small: allPartsInapplicable || !chartData,
				'without-assembly-block': !isAssembly
			})}
			style={styles.footerFlex}
		>
			<ResultsLabel
				title={getString('MAIN_RESULT_TITLE')}
				partsCount={partsCountWithoutFailed}
				printablePartsCount={numberOfPrintableParts}
				isAssembly={isAssembly}
			/>
			<CreateChartLabel
				chartData={chartData}
				isAssembly={isAssembly}
				partResultHovered={(data: any) => setPartResultHovered(data)}
			/>
			<PieFooter
				partStatuses={PART_RESULTS_LED_WITH_TITLE_INDEXES}
				chartIsEmpty={!chartData}
				is2dProject={is2dProject || isMetaDataProject}
				isAssembly={isAssembly}
				partResultHovered={partResultHovered}
			/>
		</FlexBox>
	)
}

export default memo(PrintabilityAnalysisChart)
