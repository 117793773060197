import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { ListItemText, MenuItem } from '@material-ui/core'
import Select from '@material-ui/core/Select'

import * as CustomizeInHousePrintersActions from './CustomizeInHousePrintersActions'
import { MIN_NUMBER_FIELD_VALUE, OTHER } from '../../../../Services/Constants'
import { getString } from '../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorCheckbox from '../../../Components/CastorCheckbox'
import NumberField from '../../../Components/NumberField'
import { dynamicSort } from 'Scenes/Components/MaterialSelector/Common/MultipleMaterialSelector'
import { NAME, ORGANIZATION_ID } from 'Services/Constants'

import './CustomizeInHousePrinters.scss'

class InHousePrintersSelector extends Component {
	onPrinterCompanySelectorChange = e => {
		const { onPrinterCompanySelectorChange, printerMaterialUniqueNames } =
			this.props
		onPrinterCompanySelectorChange(e.target.value, printerMaterialUniqueNames)
	}

	onPrinterNameSelectorChange = e => {
		const {
			onPrinterNameSelectorChange,
			printers,
			printersFullData,
			printerMaterialUniqueNames
		} = this.props
		onPrinterNameSelectorChange(
			e.target.value,
			printers,
			printersFullData,
			printerMaterialUniqueNames
		)
	}

	onInHousePrinterAdd = () => {
		const {
			onInHousePrinterAdd,
			userId,
			printerAmount,
			printerNotExist,
			selectedPrinter,
			selectedPrinterName,
			selectedPrinterCompany,
			selectedMaterialIds,
			selectedMaterials,
			otherMaterialsSelected,
			adminSelectedOrganizationId,
			chosenOrganizationId
		} = this.props
		onInHousePrinterAdd(
			userId,
			printerAmount,
			printerNotExist,
			selectedPrinter,
			selectedPrinterName,
			selectedPrinterCompany,
			selectedMaterialIds,
			selectedMaterials,
			otherMaterialsSelected,
			chosenOrganizationId || adminSelectedOrganizationId
		)
	}

	renderPrinterNamesMenuItems = () => {
		const { selectedPrinterCompany, printersCompanies } = this.props
		return printersCompanies[selectedPrinterCompany]
			.map(printer => {
				return (
					<MenuItem key={printer.name} value={printer.name}>
						{printer.name}
					</MenuItem>
				)
			})
			.sort((firstPrinter, secondPrinter) =>
				firstPrinter.key.localeCompare(secondPrinter.key)
			)
			.concat(
				<MenuItem key={OTHER} value={OTHER}>
					{OTHER}
				</MenuItem>
			)
	}

	renderMaterialNamesMenuItems = () => {
		const { printerMaterialsList, selectedMaterials } = this.props
		return printerMaterialsList
			.sort(dynamicSort([ORGANIZATION_ID, NAME]))
			.map(printerMaterial => {
				return (
					<MenuItem key={printerMaterial.id} value={printerMaterial.name}>
						<CastorCheckbox
							checked={selectedMaterials.indexOf(printerMaterial.name) > -1}
						/>
						<ListItemText primary={printerMaterial.name} />
					</MenuItem>
				)
			})
			.concat(
				<MenuItem key={OTHER} value={OTHER}>
					{OTHER}
				</MenuItem>
			)
	}

	renderMaterialValue = selected => {
		if (!selected?.length) {
			return ['choose']
		}
		return selected.join(', ')
	}

	render() {
		const {
			selectedPrinterCompany,
			selectedPrinterName,
			printersCompanies,
			inHousePrintersLoading,
			disableButton,
			printerAmount,
			printerMaterialsList,
			selectedMaterials,
			onMaterialsListChange,
			onPrinterAmountChange,
			zIndex = 1300
		} = this.props
		return (
			<div className="in-house-printers-selector">
				<Select
					className="select"
					value={selectedPrinterCompany}
					onChange={this.onPrinterCompanySelectorChange}
					MenuProps={{
						style: { zIndex },
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
					data-qa="data-qa-printer-company"
				>
					<MenuItem value="0" disabled>
						{getString('IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME')}
					</MenuItem>
					{Object.keys(printersCompanies).map(company => {
						return (
							<MenuItem key={company} value={company}>
								{company}
							</MenuItem>
						)
					})}
					<MenuItem key={OTHER} value={OTHER}>
						{OTHER}
					</MenuItem>
				</Select>

				<Select
					className="select"
					value={selectedPrinterName}
					onChange={this.onPrinterNameSelectorChange}
					MenuProps={{
						style: { zIndex },
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
					data-qa="data-qa-printer-name"
				>
					<MenuItem value="0" disabled>
						{getString('IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME')}
					</MenuItem>
					{printersCompanies[selectedPrinterCompany] ? (
						this.renderPrinterNamesMenuItems()
					) : (
						<div />
					)}
				</Select>

				<Select
					className="select"
					multiple
					value={
						selectedMaterials?.length
							? selectedMaterials
							: [getString('ADMIN_MATERIALS_PAGE_HEADER')]
					}
					onChange={e => onMaterialsListChange(e.target.value)}
					MenuProps={{
						style: { zIndex }
					}}
					renderValue={selected => selected.join(', ')}
					data-qa="data-qa-printer-materials"
				>
					<MenuItem value="0" disabled>
						{getString('ADMIN_MATERIALS_PAGE_HEADER')}
					</MenuItem>
					{printerMaterialsList ? this.renderMaterialNamesMenuItems() : <div />}
				</Select>
				<NumberField
					allowEmpty={false}
					value={printerAmount}
					onChangeValue={value => onPrinterAmountChange(value)}
					inputClass="number-text-field"
					idInput="plastic-form-quantity"
					placeholder="1"
					minValue={MIN_NUMBER_FIELD_VALUE}
				/>
				<ButtonWithLoader
					disabled={disableButton}
					size={20}
					style={{ padding: '5px 20px', width: 'unset' }}
					top={20}
					loading={inHousePrintersLoading}
					onClick={this.onInHousePrinterAdd}
					className="add-button"
					qaDataElementName="data-qa-add-printer"
				>
					{getString('ADD')}
				</ButtonWithLoader>
			</div>
		)
	}
}

const mapStateToProps = ({
	CustomizeInHousePrintersReducer,
	user,
	CustomizeReducer
}) => {
	const userPrinters = CustomizeInHousePrintersReducer.userId
		? CustomizeInHousePrintersReducer.userPrinters
		: user.printers

	const { printersFullData, printerMaterialUniqueNames } = user
	const { adminSelectedOrganizationId } = CustomizeReducer

	return {
		...CustomizeInHousePrintersReducer,
		userPrinters,
		printersFullData,
		printerMaterialUniqueNames,
		adminSelectedOrganizationId
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeInHousePrintersActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InHousePrintersSelector)
